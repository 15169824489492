import { Box, BoxProps, Stack } from "@mui/material";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { AttachmentsDencity } from "containers/Projects/components/Attachments/Attachments.decl";
import { AttachmentsLayout } from "containers/Projects/components/Attachments/components/AttachmentsHeader/AttachmentsHeader";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { Attachment, AttachmentInput } from "generated/graphql";
import { useContext, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../DailyDiaryContextProvider";
import { PrimarySecondaryTertiaryButtons } from "components/PrimarySecondaryTertiaryButtons";
import { PrimarySecondaryButtons } from "components/PrimarySecondaryButtons";

export type PublicApi = {
  onBeforeClose: () => void;
};

export type RecordModalFooterProps = {
  editMode?: boolean;
  attachments?: Attachment[];
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  apiRef?: React.Ref<PublicApi>;
  loading?: boolean;
  mt: BoxProps["mt"];
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
  onTertiaryClick: () => void;
};

export const RecordModalFooter: React.FC<RecordModalFooterProps> = ({
  editMode,
  attachments,
  onAttachmentsUpdated,
  apiRef,
  loading,
  mt,
  onPrimaryClick,
  onSecondaryClick,
  onTertiaryClick,
}) => {
  const { t } = useTranslation();

  const { productInstance } = useContext(DailyDiaryContext);

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    onAttachmentsUpdated(attachmentsUpdated);
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
    unloadLocalAttachments,
  } = useAttachments(attachments ?? [], handleAttachmentsUpdated);

  const handleCancelClick = () => {
    unloadLocalAttachments();
    onTertiaryClick();
  };

  const attachmentsLoading = useMemo(
    () => allAttachments.some((attach) => attach.loading),
    [allAttachments]
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      onBeforeClose: unloadLocalAttachments,
    }),
    [unloadLocalAttachments]
  );

  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={productInstance?.contract.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <Stack spacing={4} mt={4}>
        <Attachments
          editMode
          showTabs={false}
          timezone={productInstance?.contract.timeZone}
          dencity={AttachmentsDencity.Compact}
          defaultLayout={AttachmentsLayout.List}
          attachments={allAttachments}
          onAttachmentsAdd={addAttachments}
          onAttachmentRemove={removeAttachment}
          onAttachmentUpdate={updateAttachment}
          onAttachmentClick={handleAttachmentClick}
        />
        <Box display="flex" justifyContent="flex-end" mt={mt}>
          {editMode ? (
            <PrimarySecondaryButtons
              primaryBtnCaption={t("common.labels.saveChanges")}
              primaryBtnLoading={loading || attachmentsLoading}
              secondaryBtnCaption={t("common.buttons.cancel")}
              onPrimaryClick={onPrimaryClick}
              onSecondaryClick={handleCancelClick}
            />
          ) : (
            <PrimarySecondaryTertiaryButtons
              primaryBtnCaption={t("common.buttons.create")}
              primaryBtnLoading={loading || attachmentsLoading}
              secondaryBtnCaption={t("Projects.DailyDiaries.createAndAddNew")}
              secondaryBtnLoading={loading || attachmentsLoading}
              tertiaryBtnCaption={t("common.buttons.cancel")}
              onPrimaryClick={onPrimaryClick}
              onSecondaryClick={onSecondaryClick}
              onTertiaryClick={handleCancelClick}
            />
          )}
        </Box>
      </Stack>
    </>
  );
};
