import { LoadingButton } from "@mui/lab";
import { Button, Tooltip } from "@mui/material";
import { DailyDiaryItemStatus } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { isDailyDiaryItem } from "../../utilts";
import { DraftStateOwnerActionButtons } from "./DraftStateOwnerActionButtons";
import { DailyDiaryHeaderAction } from "./DailyDiaryHeader";
import { useCallback, useContext } from "react";
import { DailyDiaryHeaderContext } from "./DailyDiaryHeader.context";
import { ReviewerButtons } from "./ReviewerButtons";
import { DailyDiaryContext } from "../../DailyDiaryContextProvider";

export const OwnerButtons: React.FC = () => {
  const { t } = useTranslation();
  const {
    isReviewer,
    dailyDiary,
    loading,
    onSend,
    onRevise,
    onWaive,
    onUnWaive,
  } = useContext(DailyDiaryHeaderContext);

  const { hasRecords } = useContext(DailyDiaryContext);

  const handleActionClick = useCallback(
    (clickedOption: string) => {
      const clickedOptionAsEnum = clickedOption as DailyDiaryHeaderAction;

      switch (clickedOptionAsEnum) {
        case t(DailyDiaryHeaderAction.Send):
          onSend();
          break;
        case t(DailyDiaryHeaderAction.LockAndEdit):
          // onLockAndEdit();
          break;
        case t(DailyDiaryHeaderAction.Waive):
          onWaive();
          break;
        default:
          break;
      }
    },
    [t, onSend, onWaive /* onLockAndEdit */]
  );

  const getOwnerButtons = () => {
    if (dailyDiary && isDailyDiaryItem(dailyDiary)) {
      if (dailyDiary.status === DailyDiaryItemStatus.Rejected) {
        return (
          <LoadingButton
            variant="contained"
            size="large"
            onClick={onRevise}
            loading={loading}
          >
            {t("common.buttons.revise")}
          </LoadingButton>
        );
      } else if (
        [DailyDiaryItemStatus.Draft, DailyDiaryItemStatus.LockedDraft].includes(
          dailyDiary.status
        )
      ) {
        return (
          <DraftStateOwnerActionButtons
            loading={loading}
            sendDisabled={!hasRecords}
            onClick={handleActionClick}
          />
        );
      } else if (
        [DailyDiaryItemStatus.Approved, DailyDiaryItemStatus.Sent].includes(
          dailyDiary.status
        )
      ) {
        return null;
      } else if (dailyDiary.status === DailyDiaryItemStatus.Waived) {
        return (
          <Button
            variant="outlined"
            size="large"
            onClick={onUnWaive}
            disabled={loading}
          >
            {t("Projects.DailyDiaries.unwaive")}
          </Button>
        );
      }
    }
    return (
      // TODO: keep DraftStateOwnerActionButtons wrapped into Tooltip. Otherwise, when adding
      // the first record in an empty diary, there will be a flicker on Header buttons
      // AND SECTIONS HEADER BUTTONS ?!?!?!?!
      <Tooltip title="">
        <div>
          <DraftStateOwnerActionButtons
            loading={loading}
            sendDisabled
            onClick={handleActionClick}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      {isReviewer ? <ReviewerButtons /> : null}
      {getOwnerButtons()}
    </>
  );
};
