import { Box } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import {
  DailyDiaryItem,
  DailyDiaryItemStatus,
  ProductType,
} from "generated/graphql";
import { useMemo, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TabCountChip,
  TabLabel,
  TabWithCount,
} from "../TabsWithCount/TabsWithCount";
import { StyledProductItemsHeader } from "../ProductItemsView/ProductItemsView.styled";
import { useDailyDiaries } from "./hooks/useDailyDiaries";
import { useSearchParams } from "react-router-dom";
import { ProductItemsTablePublicAPI } from "../ProductItemsView/ProductItemsView.decl";
import { DailyDiariesTable } from "./components/DailyDiariesTable/DailyDiariesTable";
import { DailyDiaryExtraTab } from "./DailyDiariesView.constants";
import {
  DailyDiaryUserRole,
  useDailyDiaryUserBERole,
} from "hooks/useDailyDiaryUserBERole";
import { CommonTabs } from "constants/constants";

export type DailyDiariesViewProps = {};

export const DailyDiariesView: React.FC = () => {
  const { t } = useTranslation();
  const dailyDiaryItemsTableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [productItemsTableSelectionModel, setProductItemsTableSelectionModel] =
    useState<GridRowSelectionModel>();
  const [selectedTabId, setSelectedTabId] = useState<string | null>(
    CommonTabs.All
  );

  const [searchParams] = useSearchParams();
  const productInstanceId = searchParams.get("productInstanceId");

  const {
    dailyDiaryItems,
    loading: dailyDiariesLoading,
    hasMoreDailyDiaries,
    loadMoreDailyDiaryItems,
    dailyDiaryRequireMyAttentionItems,
    dailyDiaryRequireMyAttentionItemsLoading,
    hasMoreDailyDiariesRequireMyAttention,
    loadMoreDailyDiaryRequireMyAttentionItems,
    dailyDiariesSentByMe,
    dailyDiaryItemsSentByMeLoading,
    hasMoreDailyDiariesSentByMe,
    loadMoreDailyDiarySentByMeItems,
    dailyDiariesReviewedByMe,
    dailyDiaryItemsReviewedByMeLoading,
    hasMoreDailyDiariesReviewedByMe,
    loadMoreDailyDiaryReviewedByMeItems,
  } = useDailyDiaries(productInstanceId!);

  const [localDailyDiaryItems, setLocalDailyDiaryItems] = useState<
    DailyDiaryItem[]
  >([]);

  const { userRole, loading: userRoleLoading } = useDailyDiaryUserBERole(
    productInstanceId!
  );

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = [
      {
        id: CommonTabs.All,
        label: t("common.labels.all"),
        count: dailyDiaryItems?.length ?? 0,
      },
    ];

    const omittedStatusTabs = [
      DailyDiaryItemStatus.Draft,
      DailyDiaryItemStatus.LockedDraft,
    ];

    Object.values(DailyDiaryItemStatus).forEach((statusOption) => {
      if (omittedStatusTabs.indexOf(statusOption) < 0) {
        localTabs.push({
          id: statusOption,
          label: t(`Projects.DailyDiaries.status.${statusOption}`),
          count:
            dailyDiaryItems.filter(
              (dailyDiary) => dailyDiary.status === statusOption
            ).length || 0,
          renderer: (params) => {
            return (
              <Box>
                <TabLabel {...params} />
                <TabCountChip {...params} />
              </Box>
            );
          },
        });
      }
    });

    const requiresMyAttentionTab: TabWithCount = {
      id: DailyDiaryExtraTab.RequiresMyAttention,
      label: t(`Projects.DailyDiaries.requiresYourAttention`),
      count: dailyDiaryRequireMyAttentionItems.length,
      renderer: (params) => {
        return (
          <Box>
            <TabLabel {...params} />
            <TabCountChip {...params} />
          </Box>
        );
      },
    };

    const reviewedByMeTab: TabWithCount = {
      id: DailyDiaryExtraTab.ReviewedByMe,
      label: t(`Projects.DailyDiaries.reviewedByMe`),
      count: dailyDiariesReviewedByMe.length,
      renderer: (params) => {
        return (
          <Box>
            <TabLabel {...params} />
            <TabCountChip {...params} />
          </Box>
        );
      },
      alignRight: true,
    };

    if (dailyDiariesReviewedByMe.length) {
      localTabs.push(reviewedByMeTab);
    }

    if (userRole === DailyDiaryUserRole.Owner && dailyDiariesSentByMe.length) {
      localTabs.push({
        id: DailyDiaryExtraTab.SentByMe,
        label: t(`Projects.DailyDiaries.sentByYou`),
        count: dailyDiariesSentByMe.length,
        renderer: (params) => {
          return (
            <Box>
              <TabLabel {...params} />
              <TabCountChip {...params} />
            </Box>
          );
        },
        alignRight: !dailyDiariesReviewedByMe.length,
      });
    }

    if (dailyDiaryRequireMyAttentionItems.length) {
      localTabs.push({
        ...requiresMyAttentionTab,
        alignRight:
          !dailyDiariesReviewedByMe.length && !dailyDiariesSentByMe.length,
      });
    }

    return localTabs;
  }, [
    dailyDiaryItems,
    t,
    userRole,
    dailyDiariesReviewedByMe,
    dailyDiariesSentByMe,
    dailyDiaryRequireMyAttentionItems,
  ]);

  const handleExportToExcel = () => {
    dailyDiaryItemsTableRef.current?.exportToExcel();
  };

  const handleClearSelection = () => {
    dailyDiaryItemsTableRef.current?.clearSelection();
  };

  const hasMore = useMemo(() => {
    if (selectedTabId === DailyDiaryExtraTab.RequiresMyAttention) {
      return hasMoreDailyDiariesRequireMyAttention;
    } else if (selectedTabId === DailyDiaryExtraTab.SentByMe) {
      return hasMoreDailyDiariesSentByMe;
    } else if (selectedTabId === DailyDiaryExtraTab.ReviewedByMe) {
      return hasMoreDailyDiariesReviewedByMe;
    } else {
      // TODO: update here if other tabs need special treatment
      return hasMoreDailyDiaries;
    }
  }, [
    selectedTabId,
    hasMoreDailyDiariesRequireMyAttention,
    hasMoreDailyDiariesSentByMe,
    hasMoreDailyDiariesReviewedByMe,
    hasMoreDailyDiaries,
  ]);

  const handleLoadMore = () => {
    if (hasMore) {
      if (selectedTabId === DailyDiaryExtraTab.RequiresMyAttention) {
        loadMoreDailyDiaryRequireMyAttentionItems();
      } else if (selectedTabId === DailyDiaryExtraTab.SentByMe) {
        loadMoreDailyDiarySentByMeItems();
      } else if (selectedTabId === DailyDiaryExtraTab.ReviewedByMe) {
        loadMoreDailyDiaryReviewedByMeItems();
      } else {
        // TODO: update here if other tabs need special treatment
        loadMoreDailyDiaryItems();
      }
    }
  };

  const loading = useMemo(() => {
    if (selectedTabId === DailyDiaryExtraTab.RequiresMyAttention) {
      return dailyDiaryRequireMyAttentionItemsLoading;
    } else if (selectedTabId === DailyDiaryExtraTab.SentByMe) {
      return dailyDiaryItemsSentByMeLoading;
    } else if (selectedTabId === DailyDiaryExtraTab.ReviewedByMe) {
      return dailyDiaryItemsReviewedByMeLoading;
    } else {
      // TODO: update here if other tabs need special treatment
      return dailyDiariesLoading;
    }
  }, [
    selectedTabId,
    dailyDiaryRequireMyAttentionItemsLoading,
    dailyDiaryItemsSentByMeLoading,
    dailyDiaryItemsReviewedByMeLoading,
    dailyDiariesLoading,
  ]);

  useEffect(() => {
    if (dailyDiaryRequireMyAttentionItems.length) {
      setSelectedTabId(DailyDiaryExtraTab.RequiresMyAttention);
    }
  }, [dailyDiaryRequireMyAttentionItems]);

  useEffect(() => {
    if (selectedTabId === CommonTabs.All) {
      setLocalDailyDiaryItems(dailyDiaryItems);
    } else if (selectedTabId === DailyDiaryExtraTab.RequiresMyAttention) {
      setLocalDailyDiaryItems(dailyDiaryRequireMyAttentionItems);
    } else if (selectedTabId === DailyDiaryExtraTab.SentByMe) {
      setLocalDailyDiaryItems(dailyDiariesSentByMe);
    } else if (selectedTabId === DailyDiaryExtraTab.ReviewedByMe) {
      setLocalDailyDiaryItems(dailyDiariesReviewedByMe);
    } else {
      setLocalDailyDiaryItems(
        dailyDiaryItems.filter(
          (dailyDiaryItem) => dailyDiaryItem.status === selectedTabId
        )
      );
    }
  }, [
    selectedTabId,
    dailyDiaryItems,
    dailyDiariesSentByMe,
    dailyDiariesReviewedByMe,
    dailyDiaryRequireMyAttentionItems,
  ]);

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <StyledProductItemsHeader
        tabs={tabs}
        selectedItemsCount={productItemsTableSelectionModel?.length ?? 0}
        onSelectedTabChange={setSelectedTabId}
        selectedTabId={selectedTabId ?? undefined}
        onExportToExcel={handleExportToExcel}
        onClearSelection={handleClearSelection}
        productType={ProductType.DailyDiary}
      />
      <Box sx={{ flex: 1, width: "100%", overflow: "auto" }} mt={3}>
        <DailyDiariesTable
          selectionModel={productItemsTableSelectionModel}
          dailyDiaries={localDailyDiaryItems}
          waiveTabSelected={selectedTabId === "Waived"}
          selectedExtraTab={
            Object.values(DailyDiaryExtraTab).includes(
              selectedTabId as DailyDiaryExtraTab
            )
              ? (selectedTabId as DailyDiaryExtraTab)
              : undefined
          }
          loading={userRoleLoading || loading}
          apiRef={dailyDiaryItemsTableRef}
          onLoadMore={handleLoadMore}
          onSelectionModelChange={setProductItemsTableSelectionModel}
        />
      </Box>
    </Box>
  );
};
