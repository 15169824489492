import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export type PrimarySecondaryButtonsProps = {
  primaryBtnLoading?: boolean;
  secondaryBtnLoading?: boolean;
  secondaryBtnDisabled?: boolean;
  primaryBtnCaption?: string;
  secondaryBtnCaption?: string;
  primaryBtnDisabled?: boolean;
  primaryBtnDisabledTooltipText?: string;
  secondaryBtnColor?: LoadingButtonProps["color"];
  primaryBtnColor?: LoadingButtonProps["color"];
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
};

export const PrimarySecondaryButtons: React.FC<
  PrimarySecondaryButtonsProps
> = ({
  primaryBtnCaption,
  primaryBtnLoading,
  secondaryBtnCaption,
  secondaryBtnLoading,
  secondaryBtnDisabled,
  primaryBtnDisabled,
  primaryBtnDisabledTooltipText,
  primaryBtnColor,
  secondaryBtnColor,
  onPrimaryClick,
  onSecondaryClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <LoadingButton
        onClick={onSecondaryClick}
        loading={secondaryBtnLoading}
        disabled={secondaryBtnDisabled}
        variant="outlined"
        color={secondaryBtnColor}
        data-testid="secondary-btn"
      >
        <Typography variant="p2" fontWeight={700}>
          {secondaryBtnCaption ?? t("common.buttons.cancel")}
        </Typography>
      </LoadingButton>
      <Box ml={2}>
        <Tooltip title={primaryBtnDisabledTooltipText ?? ""}>
          <Box>
            <LoadingButton
              onClick={onPrimaryClick}
              loading={primaryBtnLoading}
              disabled={primaryBtnDisabled}
              color={primaryBtnColor}
              variant="contained"
              data-testid="primary-btn"
            >
              <Typography variant="p2" fontWeight={700}>
                {primaryBtnCaption ?? t("common.buttons.create")}
              </Typography>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
