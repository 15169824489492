import { DailyDiaryItemMixed, DailyDiaryItemStatus } from "generated/graphql";
import { DailyDiaryUserRole } from "hooks/useDailyDiaryUserBERole";
import { createContext } from "react";

export type DailyDiaryHeaderContextType = {
  loading: boolean;
  dailyDiary?: DailyDiaryItemMixed;
  dailyDiaryUserRoles?: DailyDiaryUserRole[];
  dailyDiaryStatus?: DailyDiaryItemStatus;
  isReviewer?: boolean;
  onSubmit: () => void;
  onSend: () => void;
  onApprove: () => void;
  onDeny: () => void;
  onRevise: () => void;
  onWaive: () => void;
  onUnWaive: () => void;
};

const contextDefaultValue: DailyDiaryHeaderContextType = {
  loading: false,
  onSubmit: () => {},
  onSend: () => {},
  onApprove: () => {},
  onDeny: () => {},
  onRevise: () => {},
  onWaive: () => {},
  onUnWaive: () => {},
};

export const DailyDiaryHeaderContext =
  createContext<DailyDiaryHeaderContextType>(contextDefaultValue);
