import { ApolloQueryResult } from "@apollo/client";
import {
  AvailableLookupsByContractQuery,
  AvailableLookupsByContractQueryVariables,
  CompanyLookupCollection,
  CompanyLookupCollectionStatus,
  DailyDiaryItem,
  DailyDiaryItemByDateQuery,
  DailyDiaryPreset,
  DailyDiaryPresetSchema,
  EmptyDailyDiaryItem,
  Exact,
  LookupOptionStatus,
  ProductInstance,
} from "generated/graphql";
import { availableLookupsByContractQuery } from "graphql/queries/availableLookupsByContract.query";
import { useGetDailyDiary } from "hooks/useGetDailyDiary";
import {
  DailyDiaryPresetFetchMode,
  useDailyDiaryPreset,
} from "hooks/useDailyDiaryPreset";
import { useDailyDiaryPresetSchema } from "hooks/useDailyDiaryPresetSchema";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useProductInstance } from "hooks/useProductInstance";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDiaryMetadata } from "./hooks/useDiaryMetadata";
import { useGlobalStore } from "state-management/store/globalStore";

export enum DailyDiarySection {
  Work,
  Weather,
  Manpower,
  HSE,
  General,
  Equipment,
  Delay,
}

const defaultRecordsState: Record<DailyDiarySection, boolean> = {
  [DailyDiarySection.Delay]: false,
  [DailyDiarySection.Equipment]: false,
  [DailyDiarySection.General]: false,
  [DailyDiarySection.HSE]: false,
  [DailyDiarySection.Manpower]: false,
  [DailyDiarySection.Weather]: false,
  [DailyDiarySection.Work]: false,
};

export type DailyDiaryContextType = {
  dailyDiaryPresetSchema?: DailyDiaryPresetSchema;
  dailyDiaryPreset?: DailyDiaryPreset;
  contractLookupCollections: CompanyLookupCollection[];
  activeContractLookupCollections: CompanyLookupCollection[];
  productInstance?: ProductInstance;
  dailyDiaryItemFetched: boolean;
  crtRevisionId?: string;
  isLastRevisionSelected?: boolean; // TODO: check again why this is necessary. Possibly after adding Status on each DailyDiaryItem.revision, we can base the logic on the status itself instead of if isLastRevision
  refetchDDByDate?: (
    variables?:
      | Partial<
          Exact<{
            productInstanceId: string;
            date: any;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<DailyDiaryItemByDateQuery>>;
  dailyDiary?: DailyDiaryItem;
  emptyDailyDiary?: EmptyDailyDiaryItem;
  hasRecords: boolean;
  setHasRecords: (value: boolean, section: DailyDiarySection) => void;
  loading?: boolean;
  dailyDiaryLoading?: boolean;
};

const defaultContextValue: DailyDiaryContextType = {
  dailyDiaryPresetSchema: undefined,
  dailyDiaryPreset: undefined,
  contractLookupCollections: [],
  activeContractLookupCollections: [],
  productInstance: undefined,
  dailyDiaryItemFetched: false,
  hasRecords: false,
  refetchDDByDate: undefined,
  dailyDiary: undefined,
  emptyDailyDiary: undefined,
  loading: false,
  dailyDiaryLoading: false,
  setHasRecords: () => {},
};

export const DailyDiaryContext = React.createContext(defaultContextValue);

export const DailyDiaryContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const setGlobalLoading = useGlobalStore(({ setLoading }) => setLoading);
  const { productInstanceId, date: dailyDiaryDate } = useParams();
  const { dailyDiaryId, revisionId, changeDiaryMetadata } = useDiaryMetadata();
  const [hasRecords, setHasRecords] =
    useState<Record<DailyDiarySection, boolean>>(defaultRecordsState);

  const { productInstance, loading: productInstanceLoading } =
    useProductInstance(productInstanceId!);
  const { dailyDiaryPresetSchema, loading: ddPresetSchemaLoading } =
    useDailyDiaryPresetSchema();

  const {
    data: contractLookupCollections,
    loading: contractLookupCollectionsLoading,
  } = useGraphQuery<
    AvailableLookupsByContractQuery,
    AvailableLookupsByContractQueryVariables
  >(availableLookupsByContractQuery, {
    variables: { contractId: productInstance?.contractId! },
    skip: !productInstance,
  });

  const { dailyDiaryPreset, loading: dailyDiaryPresetLoading } =
    useDailyDiaryPreset(
      DailyDiaryPresetFetchMode.ByProductInstanceId,
      undefined,
      productInstanceId!
    );

  const {
    dailyDiary,
    emptyDailyDiary,
    loading: dailyDiaryLoading,
    dailyDiaryItemFetched,
    refetchDDByDate,
  } = useGetDailyDiary({
    date: dailyDiaryDate!,
    productInstanceId: productInstanceId!,
  });

  const orderedContractLookupCollections = useMemo(() => {
    const localLookupCollections = JSON.parse(
      JSON.stringify(
        (contractLookupCollections?.availableLookupsByContract.items ||
          []) as CompanyLookupCollection[]
      )
    ) as CompanyLookupCollection[];

    localLookupCollections.forEach((lookupCollection) => {
      lookupCollection.options.items.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
    });

    return localLookupCollections;
  }, [contractLookupCollections]);

  const activeContractLookupCollections = useMemo(() => {
    return orderedContractLookupCollections
      .filter(
        (collection) =>
          collection.status === CompanyLookupCollectionStatus.Active
      )
      .map((collection) => {
        return {
          ...collection,
          options: {
            ...collection.options,
            items: collection.options.items.filter(
              (option) => option.status === LookupOptionStatus.Active
            ),
          },
        };
      }) as CompanyLookupCollection[];
  }, [orderedContractLookupCollections]);

  const handleRecordsCountChange = useCallback(
    (value: boolean, section: DailyDiarySection) => {
      setHasRecords((crt) => ({ ...crt, [section]: value }));
    },
    []
  );

  const hasDiaryRecords = useMemo(
    () => Object.values(hasRecords).some((value) => value === true),
    [hasRecords]
  );

  const loading = useMemo(
    () =>
      ddPresetSchemaLoading ||
      contractLookupCollectionsLoading ||
      productInstanceLoading ||
      dailyDiaryPresetLoading,
    [
      ddPresetSchemaLoading,
      contractLookupCollectionsLoading,
      productInstanceLoading,
      dailyDiaryPresetLoading,
    ]
  );

  useEffect(() => {
    if (dailyDiary && !dailyDiaryId) {
      // add dailyDiaryId to url
      changeDiaryMetadata(dailyDiary.id, dailyDiary.latestRevision.id);
    }
  }, [dailyDiary, dailyDiaryId, changeDiaryMetadata]);

  useEffect(() => {
    setGlobalLoading(dailyDiaryLoading);
  }, [dailyDiaryLoading, setGlobalLoading]);

  const contextValue = useMemo(
    () => ({
      dailyDiaryPresetSchema,
      dailyDiaryPreset,
      dailyDiary,
      crtRevisionId: revisionId ?? undefined,
      isLastRevisionSelected: dailyDiary?.latestRevision.id === revisionId,
      refetchDDByDate,
      dailyDiaryItemFetched,
      productInstance,
      emptyDailyDiary,
      contractLookupCollections: orderedContractLookupCollections,
      activeContractLookupCollections,
      loading,
      dailyDiaryLoading,
      hasRecords: hasDiaryRecords,
      setHasRecords: handleRecordsCountChange,
    }),
    [
      dailyDiaryPresetSchema,
      dailyDiaryPreset,
      orderedContractLookupCollections,
      activeContractLookupCollections,
      dailyDiary,
      productInstance,
      dailyDiaryItemFetched,
      revisionId,
      refetchDDByDate,
      emptyDailyDiary,
      loading,
      dailyDiaryLoading,
      hasDiaryRecords,
      handleRecordsCountChange,
    ]
  );

  return (
    <DailyDiaryContext.Provider value={contextValue}>
      {children}
    </DailyDiaryContext.Provider>
  );
};
