import { Color, Stack, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { IconContainer } from "components/IconContainer";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { HandWaving, Warning } from "phosphor-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DailyDiaryContext } from "../DailyDiaryContextProvider";
import { ProductType } from "generated/graphql";
import moment from "moment";
import { dailyDiaryDateFormat } from "../DailyDiary.constants";
import { FormLabel } from "components/FormLabel";
import { Textarea } from "components/TextArea";
import { FormErrorLabel } from "components/FormErrorLabel";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";

type WaiveDailyDiaryModalProps = {
  onPrimaryClick: (remarks: string) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

type FormDataType = {
  remarks: string;
};

const defaultFormData: FormDataType = {
  remarks: "",
};

export const formDataValidators: DataValidators = {
  remarks: {
    validators: [ValidatorType.Required],
  },
};

export const WaiveDailyDiaryModal: React.FC<WaiveDailyDiaryModalProps> = ({
  open,
  onPrimaryClick,
  onClose,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { dailyDiary, emptyDailyDiary, hasRecords, productInstance } =
    useContext(DailyDiaryContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateForm = useCallback((formData: FormDataType) => {
    const validationResult = validateData(formData, formDataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);
    return false;
  }, []);

  const resetForm = () => {
    setFormData(defaultFormData);
    setFormDataErrors({});
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    validateForm({ remarks: evt.target.value });
  };

  const handlePrimaryBtnClick = () => {
    if (validateForm(formData)) {
      onPrimaryClick(formData.remarks);
    }
  };

  const handleModalClose = () => {
    resetForm();
    onClose?.({}, "backdropClick");
  };

  const mergedDiary = useMemo(
    () => ({
      date: dailyDiary?.date ?? emptyDailyDiary?.date,
      productInstanceId:
        dailyDiary?.productInstanceId ?? emptyDailyDiary?.productInstanceId,
    }),
    [dailyDiary, emptyDailyDiary]
  );

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <ActionsDialog
      open={open}
      iconsHeader={
        <IconContainer>
          <HandWaving
            size={24}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      title={
        <Stack display="flex" flexDirection="column" spacing={1}>
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
          >
            {t("Projects.DailyDiaries.WaiveDailyDiaryModal.title")}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]}>
            {t("Projects.DailyDiaries.WaiveDailyDiaryModal.subtitle")}
          </Typography>
          {hasRecords && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Warning color={theme.palette.warning.dark} size={18} />
              <Typography
                variant="p1"
                color={theme.palette.warning.dark}
                lineHeight="22px"
              >
                {t("Projects.DailyDiaries.WaiveDailyDiaryModal.recordsWarning")}
              </Typography>
            </Stack>
          )}
        </Stack>
      }
      content={
        mergedDiary && productInstance ? (
          <Stack spacing={4}>
            <ProductItemCard
              itemName={moment(mergedDiary.date).format(dailyDiaryDateFormat)}
              productType={ProductType.DailyDiary}
              projectName={productInstance.contract.project.friendlyName ?? ""}
              contractName={productInstance.contract.friendlyName ?? ""}
            />
            <Stack spacing={0.5}>
              <FormLabel label={t("common.labels.remarks")} required />
              <Textarea
                value={formData.remarks}
                name="remarks"
                error={!!formDataErrors.remarks}
                onChange={handleTextFieldChange}
              />
              <FormErrorLabel
                errorMessage={formDataErrors.remarks}
                dataTestId="remarks-err-msg"
              />
            </Stack>
          </Stack>
        ) : (
          <CenteredLoadingIndicator />
        )
      }
      onPrimaryClick={handlePrimaryBtnClick}
      fullWidth
      maxWidth="sm"
      onClose={handleModalClose}
      primaryBtnDisabled={!formData.remarks}
      primaryBtnCaption={t("Projects.DailyDiaries.WaiveDailyDiaryModal.waive")}
      secondaryBtnCaption={t("common.buttons.cancel")}
      {...restDialogProps}
    />
  );
};
