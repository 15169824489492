import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryHseRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryHseRecord,
  EditDailyDiaryHseRecordInput,
  AttachmentInput,
  Attachment,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicApi, RecordModalFooter } from "../../RecordModalFooter";
import { HSELookups, HSERecordForm } from "./HSERecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export type HSERecordModalProps = {
  hseRecord?: DailyDiaryHseRecord;
  hseLookups: HSELookups;
  section: DailyDiaryPresetSection;
  attachmentsPreview?: Attachment[];
  loading?: boolean;
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onAddEdit: (
    hseRecord: AddDailyDiaryHseRecordInput | EditDailyDiaryHseRecordInput
  ) => void;
  onAddContinously: (hseRecord: AddDailyDiaryHseRecordInput) => void;
  onCancel: () => void;
} & Pick<ActionsDialogProps, "onClose" | "open">;

export const HSERecordModal: React.FC<HSERecordModalProps> = ({
  hseRecord,
  hseLookups,
  section,
  attachmentsPreview,
  loading,
  onAttachmentsUpdated,
  onAddEdit,
  onAddContinously,
  onCancel,
  onClose,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const footerApiRef = useRef<PublicApi>(null);
  const { handleClose } = useDisableBackdropClick(footerApiRef, onClose);

  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const hseRecordRef = useRef<
    AddDailyDiaryHseRecordInput | EditDailyDiaryHseRecordInput
  >();

  const handleHSERecordChange = (
    updatedHseRecord: EditDailyDiaryHseRecordInput | AddDailyDiaryHseRecordInput
  ) => {
    hseRecordRef.current = updatedHseRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (hseRecord) {
        // edit
        onAddEdit(hseRecordRef.current!);
      } else {
        // add
        const { id, ...addRecordInput } =
          hseRecordRef.current! as EditDailyDiaryHseRecordInput;
        onAddEdit(addRecordInput);
      }
    }
  };

  const handleSecondaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      const { id, ...addRecordInput } =
        hseRecordRef.current! as EditDailyDiaryHseRecordInput;

      onAddContinously(addRecordInput);

      // clear modal
      formApiRef.current.reset();
      setModalFooterKey((crtKey) => crtKey + 1);
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.HSEModal.${
              hseRecord ? "editHSERecord" : "addHSERecord"
            }`
          )}
        </Typography>
      }
      fullWidth
      disableEscapeKeyDown
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <HSERecordForm
        apiRef={formApiRef}
        hseLookups={hseLookups}
        onChange={handleHSERecordChange}
        section={section}
        hseRecord={hseRecord}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        editMode={!!hseRecord}
        apiRef={footerApiRef}
        loading={loading}
        attachments={attachmentsPreview ?? hseRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={handleSecondaryBtnClick}
        onTertiaryClick={onCancel}
      />
    </BasicModal>
  );
};
