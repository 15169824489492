import {
  DailyDiaryPreset,
  DailyDiaryPresetsQuery,
  DailyDiaryPresetsQueryVariables,
} from "generated/graphql";
import { dailyDiaryPresetsQuery } from "graphql/queries/dailyDiaryPresets.query";
import { useGraphQuery } from "./useGraphQuery";
import { useMemo } from "react";

export const useDailyDiaryPresets = () => {
  const {
    data: dailyDiaryPresets,
    refetch: refetchDailyDiaryPresets,
    loading: dailyDiaryPresetsLoading,
  } = useGraphQuery<DailyDiaryPresetsQuery, DailyDiaryPresetsQueryVariables>(
    dailyDiaryPresetsQuery
  );

  const orderedPresets = useMemo(() => {
    const rawPresets = (dailyDiaryPresets?.dailyDiaryPresets.items ??
      []) as DailyDiaryPreset[];
    rawPresets.sort((preset1, preset2) =>
      preset1.name.localeCompare(preset2.name)
    );

    return rawPresets;
  }, [dailyDiaryPresets]);

  return {
    dailyDiaryPresets: orderedPresets,
    loading: dailyDiaryPresetsLoading,
    refetchDailyDiaryPresets,
  };
};
