import {
  AuthorizationActionStatus,
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditStatus,
} from "generated/graphql";
import { AuthorizationLevel } from "./AuthorizationLevel";
import { Box, BoxProps, Stack } from "@mui/material";
import { LevelConnector } from "./LevelConnector";
import { computeLevelStatus } from "../../../../../containers/Home/components/Authorizations/Authorizations.utils";
import { useMemo } from "react";

export type AuthorizationLevelsProps = {
  authAudit: AuthorizationWorkflowAudit;
  showLevelsConnector?: boolean;
  dailyDiaryVariant?: boolean;
} & BoxProps;

// TODO: lift it up
export const AuthorizationLevels: React.FC<AuthorizationLevelsProps> = ({
  authAudit,
  showLevelsConnector = true,
  dailyDiaryVariant = false,
  ...restBoxProps
}) => {
  const activeAuthAuditTrails =
    authAudit.auditTrails?.filter(
      (auditTrail) => auditTrail.status !== AuthorizationActionStatus.Pending
    ) ?? [];

  const levels = authAudit.workflow.levels
    .slice()
    .sort((a, b) => a.sequence - b.sequence); // TODO: should ideally be done in one place, or on BE

  const inProgressLevelIndex = useMemo(() => {
    return levels.findIndex(
      (level) =>
        computeLevelStatus(level, authAudit) ===
        AuthorizationWorkflowAuditStatus.InProgress
    );
  }, [levels, authAudit]);

  return (
    <Box position="relative" width="100%" {...restBoxProps}>
      {showLevelsConnector && <LevelConnector zIndex={0} />}
      <Stack spacing={4} zIndex={1} position="relative">
        {levels.map((level, index) => {
          const allLevelApproverIds = level.userIds;
          const settledTrails =
            activeAuthAuditTrails.filter((auditTrail) =>
              allLevelApproverIds.includes(auditTrail.creatorId)
            ) ?? [];
          const levelStatus = computeLevelStatus(level, authAudit);

          return (
            <AuthorizationLevel
              levelStatus={levelStatus}
              authAuditStatus={authAudit.status}
              key={level.sequence}
              authLevel={level}
              levelNotStarted={
                levelStatus === AuthorizationWorkflowAuditStatus.InProgress &&
                index > inProgressLevelIndex
              }
              settledTrails={settledTrails}
              contractTimezone={
                authAudit.regardingProductItem.productInstance.contract.timeZone
              }
              dailyDiaryVariant={dailyDiaryVariant}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
