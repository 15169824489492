import { Stack, Typography, css, styled, useTheme } from "@mui/material";
import { Info } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { DiaryBannerContainer } from "./LockedDailyDiaryBanner";
import moment from "moment";

const BannerContainer = styled(DiaryBannerContainer)(
  ({ theme }) => css`
    background-color: ${theme.palette.warning.main};
    border: none;
  `
);

type DiaryWaivedBannerProps = {
  dateWaived: string;
};

export const DiaryWaivedBanner: React.FC<DiaryWaivedBannerProps> = ({
  dateWaived,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <BannerContainer>
      <Info size={20} color={theme.palette.grey[900]} />
      <Stack direction="row" alignItems="center" ml={2}>
        <Typography variant="p2" color="grey.900">
          {t("Projects.DailyDiaries.diaryWaivedBannerMessage", {
            date: moment(dateWaived).format("MMMM Do, yyyy"),
          })}
        </Typography>
      </Stack>
    </BannerContainer>
  );
};
