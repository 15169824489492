import {
  AuthorizationWorkflow,
  AuthorizationWorkflowAuditStatus,
} from "generated/graphql";
import { Box, Stack } from "@mui/material";
import { LevelConnector } from "components/Authorization/AuthorizeDenyActionModal/components/AuthorizationLevels/LevelConnector";
import { AuthorizationLevel } from "components/Authorization/AuthorizeDenyActionModal/components/AuthorizationLevels/AuthorizationLevel";

export type AuthorizationPreviewProps = {
  workflow: AuthorizationWorkflow;
  contractTimezone: string;
};

/**
 * For now, only used in DailyDiary ReviewersPanel. Possibly `dailyDiaryVariant` to be extended as main version
 */
export const AuthorizationPreview: React.FC<AuthorizationPreviewProps> = ({
  workflow,
  contractTimezone,
}) => {
  const levels = workflow.levels
    .slice()
    .sort((a, b) => a.sequence - b.sequence);

  return (
    <Box position="relative" width="100%">
      <LevelConnector zIndex={0} />
      <Stack spacing={4} zIndex={1} position="relative">
        {levels.map((level) => {
          return (
            <AuthorizationLevel
              levelStatus={AuthorizationWorkflowAuditStatus.InProgress}
              authAuditStatus={AuthorizationWorkflowAuditStatus.InProgress}
              levelNotStarted
              key={level.sequence}
              authLevel={level}
              settledTrails={[]}
              contractTimezone={contractTimezone}
              dailyDiaryVariant
            />
          );
        })}
      </Stack>
    </Box>
  );
};
