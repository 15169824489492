import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryWeatherRecordInput,
  Attachment,
  AttachmentInput,
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryWeatherRecord,
  EditDailyDiaryWeatherRecordInput,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicApi, RecordModalFooter } from "../../RecordModalFooter";
import { WeatherRecordForm } from "./WeatherRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export type WeatherRecordModalProps = {
  weatherRecord?: DailyDiaryWeatherRecord;
  weatherLookupCollections: CompanyLookupCollection[];
  section: DailyDiaryPresetSection;
  attachmentsPreview?: Attachment[];
  loading?: boolean;
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;

  onAddEdit: (
    weatherRecord:
      | AddDailyDiaryWeatherRecordInput
      | EditDailyDiaryWeatherRecordInput
  ) => void;
  onAddContinously: (weatherRecord: AddDailyDiaryWeatherRecordInput) => void;
  onCancel: () => void;
} & Pick<ActionsDialogProps, "onClose" | "open">;

export const WeatherRecordModal: React.FC<WeatherRecordModalProps> = ({
  weatherRecord,
  weatherLookupCollections,
  section,
  attachmentsPreview,
  onAttachmentsUpdated,
  loading,
  onAddEdit,
  onAddContinously,
  onCancel,
  onClose,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const footerApiRef = useRef<PublicApi>(null);
  const { handleClose } = useDisableBackdropClick(footerApiRef, onClose);

  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const weatherRecordRef = useRef<
    AddDailyDiaryWeatherRecordInput | EditDailyDiaryWeatherRecordInput
  >();

  const handleWeatherRecordChange = (
    updatedWeatherRecord:
      | EditDailyDiaryWeatherRecordInput
      | AddDailyDiaryWeatherRecordInput
  ) => {
    weatherRecordRef.current = updatedWeatherRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (weatherRecord) {
        // edit
        onAddEdit(weatherRecordRef.current!);
      } else {
        // add
        const { id, ...addRecordInput } =
          weatherRecordRef.current! as EditDailyDiaryWeatherRecordInput;
        onAddEdit(addRecordInput);
      }
    }
  };

  const handleSecondaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      const { id, ...addRecordInput } =
        weatherRecordRef.current! as EditDailyDiaryWeatherRecordInput;

      onAddContinously(addRecordInput);

      // clear modal
      formApiRef.current.reset();
      setModalFooterKey((crtKey) => crtKey + 1);
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.WeatherModal.${
              weatherRecord ? "editWeatherRecord" : "addWeatherRecord"
            }`
          )}
        </Typography>
      }
      fullWidth
      disableEscapeKeyDown
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <WeatherRecordForm
        apiRef={formApiRef}
        weatherLookups={weatherLookupCollections}
        section={section}
        weatherRecord={weatherRecord}
        onChange={handleWeatherRecordChange}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        editMode={!!weatherRecord}
        apiRef={footerApiRef}
        loading={loading}
        attachments={attachmentsPreview ?? weatherRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={handleSecondaryBtnClick}
        onTertiaryClick={onCancel}
      />
    </BasicModal>
  );
};
