import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { dateISOFormat } from "constants/constants";
import { FormPublicApi } from "types/decl";
import {
  CompanyLookupCollection,
  DailyDiaryExtraConfigInput,
  DailyDiaryPreset,
} from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import moment from "moment";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useProductInstanceDailyDiaryExtraConfigForm } from "./useProductInstanceDailyDiaryExtraConfigForm";
import { DatePicker } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import { LookupGridItem } from "./LookupGridItem";

export type ProductInstanceDailyDiaryExtraConfigFormProps = {
  extraConfig?: DailyDiaryExtraConfigInput;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (extraConfig: DailyDiaryExtraConfigInput | null) => void;
};

export const dataValidators: DataValidators = {
  presetId: {
    validators: [ValidatorType.Required],
  },
  startDate: {
    validators: [ValidatorType.Required],
  },
};

const defaultExtraConfig: DailyDiaryExtraConfigInput = {
  startDate: "",
  presetId: "",
  equipmentLookupId: "",
  manPowerLookupId: "",
  areaLookupId: "",
  hseTypeLookupId: "",
  companyLookupId: "",
  teamLookupId: "",
  shiftLookupId: "",
  activityLookupId: "",
  disciplineLookupId: "",
};

export const ProductInstanceDailyDiaryExtraConfigForm: React.FC<
  ProductInstanceDailyDiaryExtraConfigFormProps
> = ({ extraConfig, onChange, apiRef }) => {
  const { t } = useTranslation();
  const { contractId } = useParams();

  const [formData, setFormData] = useState<DailyDiaryExtraConfigInput>({
    ...defaultExtraConfig,
    ...extraConfig,
  });
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const { lookups, dailyDiaryPresets, loading } =
    useProductInstanceDailyDiaryExtraConfigForm(contractId!);

  const resetForm = useCallback(() => {
    onChange({ startDate: undefined, presetId: "" });
  }, [onChange]);

  const handlePresetChange = (
    _: SyntheticEvent<Element, Event>,
    value: DailyDiaryPreset | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      presetId: value?.id || "",
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { presetId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleLookupChange = (
    lkpKey: keyof DailyDiaryExtraConfigInput,
    value: CompanyLookupCollection | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      [lkpKey]: value?.id || "",
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [lkpKey]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    setFormData((curData) => ({
      ...curData,
      startDate: date,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { startDate: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: DailyDiaryExtraConfigInput) => {
    const validationResult = validateData(formData, dataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);

    return false;
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [resetForm, formData, validateForm]
  );

  useEffect(() => {
    const {
      equipmentLookupId,
      manPowerLookupId,
      areaLookupId,
      hseTypeLookupId,
      companyLookupId,
      teamLookupId,
      shiftLookupId,
      activityLookupId,
      disciplineLookupId,
      ...restFormData
    } = formData;
    onChange(
      formData.startDate
        ? {
            ...restFormData,
            ...(equipmentLookupId ? { equipmentLookupId } : {}),
            ...(manPowerLookupId ? { manPowerLookupId } : {}),
            ...(areaLookupId ? { areaLookupId } : {}),
            ...(hseTypeLookupId ? { hseTypeLookupId } : {}),
            ...(companyLookupId ? { companyLookupId } : {}),
            ...(teamLookupId ? { teamLookupId } : {}),
            ...(shiftLookupId ? { shiftLookupId } : {}),
            ...(activityLookupId ? { activityLookupId } : {}),
            ...(disciplineLookupId ? { disciplineLookupId } : {}),
            startDate: moment(formData.startDate).format(dateISOFormat),
          }
        : null
    );
  }, [onChange, formData]);

  useEffect(() => {
    setFormData({ ...defaultExtraConfig, ...extraConfig });
  }, [extraConfig]);

  return (
    <>
      <Grid item md={6} xs={12}>
        <DatePicker
          label={t("common.labels.startDate")}
          format="yyyy-MM-dd"
          value={formData.startDate ? new Date(formData.startDate) : null}
          slotProps={{
            textField: {
              error: !!formDataErrors.startDate,
              helperText: formDataErrors.startDate,
              required: true,
            },
          }}
          onChange={handleStartDateChange}
          disabled={loading}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <>
            <InputLabel id="product-preset-select-label" shrink>
              {t("AdminConsole.ProductInstances.labels.preset")}
            </InputLabel>
            <Autocomplete
              disablePortal
              id="product-preset-autcomplete"
              options={dailyDiaryPresets || []}
              sx={{ maxHeight: 200 }}
              value={
                dailyDiaryPresets?.find(
                  (preset) => preset.id === formData.presetId
                ) || null
              }
              onChange={handlePresetChange}
              getOptionLabel={(preset) => preset.name}
              fullWidth
              disabled={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("AdminConsole.ProductInstances.labels.preset")}
                  required
                  error={!!formDataErrors.presetId}
                  helperText={formDataErrors.presetId}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
          </>
        </FormControl>
      </Grid>
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.equipmentLookupId) ||
          null
        }
        label={t("AdminConsole.ProductInstances.labels.equipmentLookup")}
        onChange={(_evt, value) =>
          handleLookupChange("equipmentLookupId", value)
        }
        loading={loading}
        errorMsg={formDataErrors.equipmentLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.manPowerLookupId) ||
          null
        }
        label={t("AdminConsole.ProductInstances.labels.manPowerLookup")}
        onChange={(_evt, value) =>
          handleLookupChange("manPowerLookupId", value)
        }
        loading={loading}
        errorMsg={formDataErrors.manPowerLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.areaLookupId) || null
        }
        label={t("AdminConsole.ProductInstances.labels.areaLookup")}
        onChange={(_evt, value) => handleLookupChange("areaLookupId", value)}
        loading={loading}
        errorMsg={formDataErrors.areaLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.hseTypeLookupId) ||
          null
        }
        label={t("AdminConsole.ProductInstances.labels.hseTypeLookup")}
        onChange={(_evt, value) => handleLookupChange("hseTypeLookupId", value)}
        loading={loading}
        errorMsg={formDataErrors.hseTypeLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.companyLookupId) ||
          null
        }
        label={t("AdminConsole.ProductInstances.labels.companyLookup")}
        onChange={(_evt, value) => handleLookupChange("companyLookupId", value)}
        loading={loading}
        errorMsg={formDataErrors.companyLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.teamLookupId) || null
        }
        label={t("AdminConsole.ProductInstances.labels.teamLookup")}
        onChange={(_evt, value) => handleLookupChange("teamLookupId", value)}
        loading={loading}
        errorMsg={formDataErrors.teamLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.shiftLookupId) ||
          null
        }
        label={t("AdminConsole.ProductInstances.labels.shiftLookup")}
        onChange={(_evt, value) => handleLookupChange("shiftLookupId", value)}
        loading={loading}
        errorMsg={formDataErrors.shiftLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find(
            (lookup) => lookup.id === formData.disciplineLookupId
          ) || null
        }
        label={t("AdminConsole.ProductInstances.labels.disciplineLookup")}
        onChange={(_evt, value) =>
          handleLookupChange("disciplineLookupId", value)
        }
        loading={loading}
        errorMsg={formDataErrors.disciplineLookupId}
      />
      <LookupGridItem
        options={lookups || []}
        value={
          lookups?.find((lookup) => lookup.id === formData.activityLookupId) ||
          null
        }
        label={t("AdminConsole.ProductInstances.labels.activityLookup")}
        onChange={(_evt, value) =>
          handleLookupChange("activityLookupId", value)
        }
        loading={loading}
        errorMsg={formDataErrors.activityLookupId}
      />
    </>
  );
};
