import {
  Box,
  BoxProps,
  css,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { ProductType } from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { unofficialThemeColors } from "theme/extendedTheme";

// Note: remove this when it's added to be as a real new product
export enum ProductTypeExtra {
  AdvanceWarning = "AdvanceWarning",
}

type ExtendedProductType = ProductType | ProductTypeExtra;

const ItemTagContainer = styled(Box)<{ type: ExtendedProductType }>(
  ({ type }) => {
    const computeTagBackgroundColor = (type: ExtendedProductType) => {
      switch (type) {
        case ProductType.RisksRegister:
          return unofficialThemeColors.orange;
        case ProductType.EarlyWarnings:
          return unofficialThemeColors.blueDarker;
        case ProductType.Events:
          return unofficialThemeColors.red1;
        case ProductType.Instructions:
          return unofficialThemeColors.blueIntense;
        case ProductType.CompEvents:
          return unofficialThemeColors.purple;
        case ProductType.DailyDiary:
          return unofficialThemeColors.greenDarker;
        case ProductType.Claims:
          return unofficialThemeColors.purple; // TODO: to fill in with the right color
        case ProductTypeExtra.AdvanceWarning:
          return unofficialThemeColors.blueEvenDarker;
        case ProductType.Variations:
          return unofficialThemeColors.blueVO;
      }
    };

    return css`
      height: 24px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: ${computeTagBackgroundColor(type)};
    `;
  }
);

export const ItemTag: React.FC<{ type: ExtendedProductType } & BoxProps> = ({
  type,
  ...restBoxProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const title = useMemo(() => {
    switch (type) {
      case ProductType.RisksRegister:
        return t("common.labels.risk");
      case ProductType.EarlyWarnings:
        return t("common.labels.ew");
      case ProductType.Events:
        return t("common.labels.event");
      case ProductType.Instructions:
        return t("common.labels.instructionShort");
      case ProductType.CompEvents:
        return t("common.labels.compEventShort");
      case ProductType.DailyDiary:
        return t("common.labels.diary");
      case ProductType.Claims:
        return t("common.labels.claim");
      case ProductTypeExtra.AdvanceWarning:
        return t("common.labels.aw");
      case ProductType.Variations:
        return t("common.labels.vo");
    }
  }, [type, t]);

  return (
    <ItemTagContainer type={type} {...restBoxProps}>
      <Typography
        fontWeight={700}
        px={1}
        py={0.5}
        fontSize="10px"
        lineHeight="12px"
        textTransform="uppercase"
        color={theme.palette.common.white}
      >
        {title}
      </Typography>
    </ItemTagContainer>
  );
};
