import { Color, Stack, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { IconContainer } from "components/IconContainer";
import { ArrowUUpLeft } from "phosphor-react";
import { useTranslation } from "react-i18next";

type UnwaiveDailyDiaryModalProps = {
  onPrimaryClick: () => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const UnwaiveDailyDiaryModal: React.FC<UnwaiveDailyDiaryModalProps> = ({
  onPrimaryClick,
  onClose,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleModalClose = () => {
    onClose?.({}, "backdropClick");
  };

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer>
          <ArrowUUpLeft
            size={24}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      title={
        <Stack display="flex" flexDirection="column" spacing={1}>
          <Typography
            variant="h3"
            fontWeight={600}
            color={theme.palette.grey[800]}
          >
            {t("Projects.DailyDiaries.UnwaiveDailyDiaryModal.title")}
          </Typography>
          <Typography variant="p1" color={theme.palette.grey[700]}>
            {t("Projects.DailyDiaries.UnwaiveDailyDiaryModal.subtitle")}
          </Typography>
        </Stack>
      }
      onPrimaryClick={onPrimaryClick}
      fullWidth
      maxWidth="sm"
      onClose={handleModalClose}
      primaryBtnCaption={t("Projects.DailyDiaries.unwaive")}
      secondaryBtnCaption={t("common.buttons.cancel")}
      {...restDialogProps}
    />
  );
};
