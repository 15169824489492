import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryGeneralRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryGeneralRecord,
  EditDailyDiaryGeneralRecordInput,
  AttachmentInput,
  Attachment,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicApi, RecordModalFooter } from "../../RecordModalFooter";
import { GeneralRecordForm } from "./GeneralRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export type GeneralRecordModalProps = {
  generalRecord?: DailyDiaryGeneralRecord;
  section: DailyDiaryPresetSection;
  attachmentsPreview?: Attachment[];
  loading?: boolean;
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onAddEdit: (
    generalRecord:
      | AddDailyDiaryGeneralRecordInput
      | EditDailyDiaryGeneralRecordInput
  ) => void;
  onAddContinously: (generalRecord: AddDailyDiaryGeneralRecordInput) => void;
  onCancel: () => void;
} & Pick<ActionsDialogProps, "onClose" | "open">;

export const GeneralRecordModal: React.FC<GeneralRecordModalProps> = ({
  generalRecord,
  section,
  attachmentsPreview,
  onAttachmentsUpdated,
  loading,
  onAddEdit,
  onAddContinously,
  onCancel,
  onClose,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const footerApiRef = useRef<PublicApi>(null);
  const { handleClose } = useDisableBackdropClick(footerApiRef, onClose);

  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const generalRecordRef = useRef<
    AddDailyDiaryGeneralRecordInput | EditDailyDiaryGeneralRecordInput
  >();

  const handleGeneralRecordChange = (
    updatedGeneralRecord:
      | EditDailyDiaryGeneralRecordInput
      | AddDailyDiaryGeneralRecordInput
  ) => {
    generalRecordRef.current = updatedGeneralRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (generalRecord) {
        // edit
        onAddEdit(generalRecordRef.current!);
      } else {
        // add
        const { id, ...addRecordInput } =
          generalRecordRef.current! as EditDailyDiaryGeneralRecordInput;
        onAddEdit(addRecordInput);
      }
    }
  };

  const handleSecondaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      const { id, ...addRecordInput } =
        generalRecordRef.current! as EditDailyDiaryGeneralRecordInput;

      onAddContinously(addRecordInput);

      // clear modal
      formApiRef.current.reset();
      setModalFooterKey((crtKey) => crtKey + 1);
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.GeneralModal.${
              generalRecord ? "editGeneralRecord" : "addGeneralRecord"
            }`
          )}
        </Typography>
      }
      fullWidth
      disableEscapeKeyDown
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <GeneralRecordForm
        generalRecord={generalRecord}
        section={section}
        apiRef={formApiRef}
        onChange={handleGeneralRecordChange}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        editMode={!!generalRecord}
        apiRef={footerApiRef}
        loading={loading}
        attachments={attachmentsPreview ?? generalRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={handleSecondaryBtnClick}
        onTertiaryClick={onCancel}
      />
    </BasicModal>
  );
};
