import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { DeleteConfirmationModal } from "components/DeleteConfirmationModal";
import {
  AddDailyDiaryWeatherRecordInput,
  AttachmentInput,
  DailyDiaryPresetSection,
  DailyDiaryWeatherRecord,
  EditDailyDiaryWeatherRecordInput,
} from "generated/graphql";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DailyDiaryContext,
  DailyDiarySection,
} from "../../DailyDiaryContextProvider";
import { BaseHeaderSection } from "../BaseSectionHeader";
import { useWeatherSection } from "./useWeatherSection";
import { WeatherRecordModal } from "./WeatherRecordModal/WeatherRecordModal";
import { WeatherTable } from "./WeatherTable/WeatherTable";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { ImportWeatherRecordsModal } from "./ImportWeatherRecordsModal";
import { useDiaryMetadata } from "../../hooks/useDiaryMetadata";

export type WeatherSectionProps = {
  section: DailyDiaryPresetSection;
};

export const WeatherSection: React.FC<WeatherSectionProps> = ({ section }) => {
  const { t } = useTranslation();
  const [updatedAttachments, setUpdatedAttachments] =
    useState<AttachmentInput[]>();

  const { dailyDiaryId: dailyDiaryIdSearchParam } = useDiaryMetadata();

  const [recordToDeleteEdit, setRecordToDeleteEdit] =
    useState<DailyDiaryWeatherRecord>();
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  const {
    modalVisibility: importModalVisibility,
    toggleModalVisibility: toggleImportModalVisibility,
  } = useBasicModal();

  const {
    dailyDiary,
    emptyDailyDiary,
    contractLookupCollections,
    activeContractLookupCollections,
    loading: contextLoading,
    crtRevisionId,
    refetchDDByDate,
    hasRecords,
    setHasRecords,
  } = useContext(DailyDiaryContext);
  const { modalVisibility, toggleModalVisibility } = useBasicModal();
  const {
    weatherRecords,
    loading: weatherRecordsLoading,
    refetchDDWeatherRecords,
    addWeatherRecord,
    editWeatherRecord,
    removeWeatherRecord,
  } = useWeatherSection(dailyDiary, crtRevisionId);

  const toggleConfirmationModalVisibility = () => {
    setConfirmationModalVisibility((crtState) => !crtState);
  };

  const handleAddWeatherRecord = () => {
    toggleModalVisibility();
  };

  const triggerEditRecordFlow = (recordId: GridRowId) => {
    const record = weatherRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    toggleModalVisibility();
  };

  const triggerRemoveFlow = (recordId: GridRowId) => {
    const record = weatherRecords.find((record) => record.id === recordId);
    setRecordToDeleteEdit(record);
    setConfirmationModalVisibility(true);
  };

  const handleAttachmentsUpdated = (attachmentInputs: AttachmentInput[]) => {
    setUpdatedAttachments(attachmentInputs);
  };

  const addEditRecord = async (
    weatherRecord:
      | AddDailyDiaryWeatherRecordInput
      | EditDailyDiaryWeatherRecordInput
  ) => {
    if (recordToDeleteEdit) {
      await editWeatherRecord({
        variables: {
          input: {
            ...(weatherRecord as EditDailyDiaryWeatherRecordInput),
            id: recordToDeleteEdit.id,
            attachments:
              updatedAttachments ??
              attachmentsToAttachmentInputs(
                recordToDeleteEdit.attachments ?? []
              ),
          },
        },
      });
    } else {
      await addWeatherRecord({
        variables: {
          input: {
            ...(weatherRecord as AddDailyDiaryWeatherRecordInput),
            ...(dailyDiary?.id
              ? { dailyDiaryId: dailyDiary.id }
              : {
                  date: emptyDailyDiary?.date,
                  productInstanceId: emptyDailyDiary?.productInstanceId,
                }),
            attachments: updatedAttachments ?? [],
          },
        },
      });
    }

    setUpdatedAttachments(undefined);
  };

  const handleModalPrimaryClick = async (
    weatherRecord:
      | AddDailyDiaryWeatherRecordInput
      | EditDailyDiaryWeatherRecordInput
  ) => {
    addEditRecord(weatherRecord);

    handleWeatherRecordModalClose();
  };

  const handleModalSecondaryClick = async (
    weatherRecord:
      | AddDailyDiaryWeatherRecordInput
      | EditDailyDiaryWeatherRecordInput
  ) => {
    addEditRecord(weatherRecord);
  };

  const handleSuccessfulImport = () => {
    toggleImportModalVisibility();
    if (dailyDiaryIdSearchParam) {
      refetchDDWeatherRecords();
    } else {
      refetchDDByDate?.();
    }
  };

  const handleWeatherRecordModalClose = () => {
    setRecordToDeleteEdit(undefined);
    toggleModalVisibility();
  };

  const handleRemoveRecord = () => {
    toggleConfirmationModalVisibility();
    removeWeatherRecord({ variables: { id: recordToDeleteEdit?.id! } });
    setRecordToDeleteEdit(undefined);
  };

  useEffect(() => {
    if (recordToDeleteEdit && !weatherRecords.includes(recordToDeleteEdit)) {
      const updatedRecord = weatherRecords.find(
        (rec) => rec.id === recordToDeleteEdit.id
      );
      if (updatedRecord) {
        setRecordToDeleteEdit(updatedRecord);
      }
    }
  }, [weatherRecords, recordToDeleteEdit]);

  useEffect(() => {
    if (!recordToDeleteEdit) {
      setUpdatedAttachments(undefined);
    }
  }, [recordToDeleteEdit]);

  useEffect(() => {
    if (!hasRecords && !!weatherRecords.length) {
      setHasRecords(true, DailyDiarySection.Weather);
    } else if (hasRecords && !weatherRecords.length) {
      setHasRecords(false, DailyDiarySection.Weather);
    }
  }, [weatherRecords, hasRecords, setHasRecords]);

  return (
    <>
      <DeleteConfirmationModal
        title={t("Projects.DailyDiaries.RemoveConfirmationModal.title")}
        subtitle={t("Projects.DailyDiaries.RemoveConfirmationModal.subtitle")}
        open={confirmationModalVisibility}
        onPrimaryClick={handleRemoveRecord}
        onSecondaryClick={toggleConfirmationModalVisibility}
      />
      {importModalVisibility && (
        <ImportWeatherRecordsModal
          open={importModalVisibility}
          section={section}
          lookups={contractLookupCollections}
          loading={contextLoading}
          onClose={toggleImportModalVisibility}
          onImportSuccess={handleSuccessfulImport}
        />
      )}
      <WeatherRecordModal
        open={modalVisibility}
        onAttachmentsUpdated={handleAttachmentsUpdated}
        onAddEdit={handleModalPrimaryClick}
        onAddContinously={handleModalSecondaryClick}
        onCancel={handleWeatherRecordModalClose}
        onClose={handleWeatherRecordModalClose}
        loading={weatherRecordsLoading}
        section={section}
        attachmentsPreview={
          updatedAttachments && recordToDeleteEdit
            ? recordToDeleteEdit.attachments?.filter((attach) =>
                updatedAttachments.find(
                  (crtAttach) => crtAttach.id === attach.id
                )
              )
            : undefined
        }
        weatherRecord={recordToDeleteEdit}
        weatherLookupCollections={activeContractLookupCollections}
      />
      <Box width="100%">
        <BaseHeaderSection
          title={t("Projects.DailyDiaries.sections.weather")}
          onAdd={handleAddWeatherRecord}
          onImport={toggleImportModalVisibility}
          mb={3}
        />
        <WeatherTable
          section={section}
          records={weatherRecords}
          weatherLookupCollections={contractLookupCollections}
          loading={weatherRecordsLoading || contextLoading}
          onEdit={triggerEditRecordFlow}
          onRemove={triggerRemoveFlow}
        />
      </Box>
    </>
  );
};
