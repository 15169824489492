import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useInvolvedUsers } from "containers/Projects/hooks/useInvolvedUsers";
import { ProductType } from "generated/graphql";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommentsContextProvider } from "./Comments.context";
import { CommentsEditor } from "./CommentsEditor";
import { CommentsList } from "./CommentsList";
import { useComments } from "./useComments";
import { getUserName } from "helpers/miscelaneous";
import { ChatText } from "phosphor-react";

// TODO: maybe have a context with these common props
export type CommentsProps = {
  productType: ProductType;
  productItemId: string;
  productInstanceId: string;
  readOnly?: boolean;
  versionId?: string;
  timezone?: string;
  /** Used by DailyDiary to insert a note to redirect the users towards ReviewersPanel when the diary is rejected */
  subHeading?: React.ReactNode;
  onCommentAdded?: (noOfMentions: number) => void; // This is meant just for informative purposes (so the parent can refresh changelog)
  onCommentsLoaded?: (count: number, hasMore?: boolean) => void;
};

export const Comments: React.FC<CommentsProps> = ({
  productType,
  productItemId,
  productInstanceId,
  readOnly,
  versionId,
  subHeading,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  onCommentAdded,
  onCommentsLoaded,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    comments,
    hasMore,
    loading,
    addComment,
    commentsLoading,
    loadMore,
    replyComment,
  } = useComments(productItemId, productType, versionId);
  const { candidates: mentionableUsers, loading: mentionableUsersLoading } =
    useInvolvedUsers(productInstanceId!);

  const handleSendComment = (comment: string, commentToReplyToId?: string) => {
    const noOfMentions = comment.match(/data-id/g)?.length ?? 0;

    if (commentToReplyToId) {
      replyComment({
        productInstanceId,
        content: comment,
        commentId: commentToReplyToId,
      });
    } else {
      addComment({
        productInstanceId,
        itemId: productItemId,
        content: comment,
        versionId,
      });
    }
    onCommentAdded?.(noOfMentions);
  };

  const typeaheadOptions = useMemo(() => {
    return mentionableUsers.map((user) => ({
      userId: user.id,
      name: getUserName(user),
    }));
  }, [mentionableUsers]);

  useEffect(() => {
    // load initial batch of comments
    loadMore();
  }, [loadMore]);

  useEffect(() => {
    onCommentsLoaded?.(comments.length, hasMore);
  }, [onCommentsLoaded, comments, hasMore]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        mb={comments.length > 0 || !readOnly ? 3 : 0}
      >
        <ChatText size={24} color={theme.palette.grey[800]} />
        <Typography variant="h3" color="grey.800">
          {t("Comments.comments")}
        </Typography>
      </Stack>
      {subHeading && <Box pb={3}>{subHeading}</Box>}
      <Box mb={comments.length ? 3 : 0}>
        <CommentsContextProvider timezone={timezone}>
          <CommentsList
            comments={comments}
            loading={commentsLoading}
            hasMore={hasMore}
            readOnly={readOnly}
            onLoadMoreComments={loadMore}
            inlineEditorDisabled={loading || mentionableUsersLoading}
            typeaheadOptions={typeaheadOptions}
            onSendReply={handleSendComment}
          />
        </CommentsContextProvider>
      </Box>
      {!readOnly && (
        <CommentsEditor
          onSend={handleSendComment}
          disabled={loading || mentionableUsersLoading}
          typeaheadOptions={typeaheadOptions}
        />
      )}
    </Box>
  );
};
