import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryDelayRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryDelayRecord,
  EditDailyDiaryDelayRecordInput,
  AttachmentInput,
  Attachment,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicApi, RecordModalFooter } from "../../RecordModalFooter";
import { DelayOptionalColumn } from "../DelayTable/DelayTable";
import { DelayLookups, DelayRecordForm } from "./DelayRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export type DelayRecordModalProps = {
  delayRecord?: DailyDiaryDelayRecord;
  delayLookups: DelayLookups;
  section: DailyDiaryPresetSection;
  optionalColumns: DelayOptionalColumn[];
  attachmentsPreview?: Attachment[];
  loading?: boolean;
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onAddEdit: (
    delayRecord: AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput
  ) => void;
  onAddContinously: (delayRecord: AddDailyDiaryDelayRecordInput) => void;
  onCancel: () => void;
} & Pick<ActionsDialogProps, "onClose" | "open">;

export const DelayRecordModal: React.FC<DelayRecordModalProps> = ({
  delayRecord,
  delayLookups,
  optionalColumns,
  attachmentsPreview,
  section,
  loading,
  onAttachmentsUpdated,
  onAddEdit,
  onAddContinously,
  onCancel,
  onClose,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const footerApiRef = useRef<PublicApi>(null);
  const { handleClose } = useDisableBackdropClick(footerApiRef, onClose);

  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const delayRecordRef = useRef<
    AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput
  >();

  const handleDelayRecordChange = (
    updatedDelayRecord:
      | EditDailyDiaryDelayRecordInput
      | AddDailyDiaryDelayRecordInput
  ) => {
    delayRecordRef.current = updatedDelayRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (delayRecord) {
        // edit
        onAddEdit(delayRecordRef.current!);
      } else {
        // add
        const { id, ...addRecordInput } =
          delayRecordRef.current! as EditDailyDiaryDelayRecordInput;
        onAddEdit(addRecordInput);
      }
    }
  };

  const handleSecondaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      const { id, ...addRecordInput } =
        delayRecordRef.current! as EditDailyDiaryDelayRecordInput;

      onAddContinously(addRecordInput);

      // clear modal
      formApiRef.current.reset();
      setModalFooterKey((crtKey) => crtKey + 1);
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.DelayModal.${
              delayRecord ? "editDelayRecord" : "addDelayRecord"
            }`
          )}
        </Typography>
      }
      disableEscapeKeyDown
      fullWidth
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <DelayRecordForm
        apiRef={formApiRef}
        delayLookups={delayLookups}
        optionalColumns={optionalColumns}
        section={section}
        onChange={handleDelayRecordChange}
        delayRecord={delayRecord}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        editMode={!!delayRecord}
        apiRef={footerApiRef}
        loading={loading}
        attachments={attachmentsPreview ?? delayRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={handleSecondaryBtnClick}
        onTertiaryClick={onCancel}
      />
    </BasicModal>
  );
};
