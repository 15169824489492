import { gql } from "@apollo/client";

export const sendAuthorizationAcceptanceWithResultMutation = gql`
  mutation sendAuthorizationAcceptanceWithResult(
    $authorizationWorkflowAuditId: ID!
  ) {
    sendAuthorizationAcceptanceWithResult(
      authorizationWorkflowAuditId: $authorizationWorkflowAuditId
    ) {
      success
      actionExecuted
    }
  }
`;
