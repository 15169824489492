import { Typography } from "@mui/material";
import { NewAuthPageTemplate } from "components/NewAuthPageTemplate";
import { snackbarAutoHideDuration } from "constants/constants";
import { SecurityCodeValidationView } from "containers/ResetPassword/SetNewPassword/SecurityCodeValidationView";
import { NewAppPaths } from "helpers/paths/paths";
import { useSnackbar } from "notistack";
import { AuthContext, AuthContextDataType } from "providers/auth.context";
import { useConfig } from "providers/config.context";
import { useContext, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { isMFAResponse, login, verifyCode } from "services/AuthService";
import { ErrorsType } from "types";

export type MFARouterRequiredState = {
  email: string;
  password: string;
  session: string;
  keepMeLoggedIn: boolean;
};

const backendToFrontendErrorMapping: Record<string, string> = {
  "Invalid code or auth state for the user.": "MFA.errorMessages.invalidCode",
  "Invalid session for the user, session is expired.":
    "MFA.errorMessages.expiredCode",
};

export const MFA = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { config } = useConfig();
  const { isLoggedIn, setContextData } = useContext(AuthContext);
  const state = location.state as MFARouterRequiredState | undefined;
  const resentCodeSessionRef = useRef<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const transComponentsConfig = useMemo(
    () => ({
      i: <Typography variant="p2" fontStyle="italic" px={0.5} />,
    }),
    []
  );

  const handleEnterSecurityCode = async (code: string) => {
    if (!state) return;

    setLoading(true);
    const result = await verifyCode(
      config.REACT_APP_AUTH_BASE_URL,
      state.email,
      code,
      resentCodeSessionRef.current || state.session,
      state.keepMeLoggedIn
    );
    setLoading(false);

    if ((result as AuthContextDataType).AccessToken) {
      setContextData(result as AuthContextDataType);
      resentCodeSessionRef.current = undefined;
    } else {
      const errorMsg = (result as ErrorsType).Errors[0];
      const localErrMsg = backendToFrontendErrorMapping[errorMsg];
      const computedErrMsg = t(localErrMsg) ?? errorMsg;

      computedErrMsg &&
        enqueueSnackbar(
          <Trans i18nKey={computedErrMsg} components={transComponentsConfig} />,
          {
            variant: "error",
            persist: true,
          }
        );
    }
  };

  const handleResendCode = async () => {
    if (!state) return;

    setLoading(true);
    const result = await login(
      config.REACT_APP_AUTH_BASE_URL,
      state.email,
      state.password,
      state.keepMeLoggedIn
    );
    setLoading(false);

    if (isMFAResponse(result)) {
      enqueueSnackbar(t("MFA.messageResent"), {
        autoHideDuration: snackbarAutoHideDuration,
        variant: "info",
      });
      resentCodeSessionRef.current = result.Session;
    } else if ((result as AuthContextDataType).AccessToken) {
      setContextData(result as AuthContextDataType);
    } else {
      const errorMsg = (result as ErrorsType).Errors[0];
      errorMsg &&
        enqueueSnackbar(errorMsg, {
          variant: "error",
          persist: true,
        });
    }
  };

  if (!state && !isLoggedIn) {
    return <Navigate replace to={NewAppPaths.nonAuthorized.Login} />;
  }

  if (isLoggedIn) {
    return <Navigate replace to={NewAppPaths.authorized.Home} />;
  }

  return (
    <NewAuthPageTemplate>
      <SecurityCodeValidationView
        variant="MFA"
        onEnterSecurityCode={handleEnterSecurityCode}
        onResendCode={handleResendCode}
        loading={loading}
      />
    </NewAuthPageTemplate>
  );
};
