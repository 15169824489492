import { gql } from "@apollo/client";
import { contractLiteFields } from "graphql/fragments/contractLite.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { productLiteFields } from "graphql/fragments/productLite.fragment";

export const productInstanceQuery = gql`
  ${creatorFields}
  ${contractLiteFields}
  ${productLiteFields}

  query productInstanceLite($id: ID!) {
    productInstance(id: $id) {
      id
      description
      numberingFormat
      soloModeSupported
      contractId
      productId
      productSchemaId
      statusCollectionId
      status
      dateCreated
      creatorId
      creator {
        ...CreatorFields
      }
      product {
        ...ProductLiteFields
      }
      #roles: ProductUserRoleList!
      contract {
        ...ContractLiteFields
      }
      #productSchema: ProductSchema!
      #statusCollection: ItemStatusCollection!
      extraConfig {
        __typename
        ... on DailyDiaryExtraConfig {
          presetId
          manPowerLookupId
          equipmentLookupId
          areaLookupId
          hseTypeLookupId
          companyLookupId
          teamLookupId
          shiftLookupId
          activityLookupId
          disciplineLookupId
          startDate
        }
      }
    }
  }
`;
