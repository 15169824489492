import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { dateTimeISOFormat } from "constants/constants";
import { DailyDiaryItem, DailyDiaryItemRevision } from "generated/graphql";
import { NewAppPaths } from "helpers/paths/paths";
import moment from "moment";
import { ArrowsClockwise, CaretRight } from "phosphor-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type RevisionSelectorProps = {
  currentRevision: DailyDiaryItemRevision;
  dailyDiary: DailyDiaryItem;
};

export const RevisionSelector: React.FC<RevisionSelectorProps> = ({
  dailyDiary,
  currentRevision,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const revisionsOrdered = useMemo(
    () =>
      dailyDiary.revisions
        .slice()
        .sort((rev1, rev2) => Number(rev2.number) - Number(rev1.number)),
    [dailyDiary.revisions]
  );

  const handleSelectChange = (event: SelectChangeEvent<string | null>) => {
    handleRevisionClick(event.target.value!);
  };

  const handleRevisionClick = (revisionId: string) => {
    if (revisionId !== currentRevision.id) {
      navigate(
        NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
          dailyDiary.productInstanceId,
          dailyDiary.date,
          dailyDiary.id,
          revisionId
        )
      );
    }
  };

  return (
    <Select
      id="revision-selector"
      value={currentRevision.id}
      onChange={handleSelectChange}
      variant="outlined"
      size="small"
      sx={{ height: "36px !important" }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      renderValue={(crtRevisionId) => {
        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <ArrowsClockwise color={theme.palette.grey[800]} />
            <Typography variant="p2" fontWeight={600} color="grey.800">
              {t("Projects.DailyDiaries.revisionNo", {
                revisionNo: Number(
                  revisionsOrdered.find((crt) => crt.id === crtRevisionId)!
                    .number
                ),
              })}
            </Typography>
          </Stack>
        );
      }}
    >
      {revisionsOrdered.map((revision) => (
        <MenuItem
          key={revision.id}
          value={revision.id}
          sx={{ py: 1, height: "fit-content", minWidth: "250px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            pr={1}
          >
            <Stack spacing={0.5}>
              <Typography variant="p1" color="grey.800" fontWeight={600}>
                {t("Projects.DailyDiaries.revisionNo", {
                  revisionNo: Number(revision.number),
                })}
              </Typography>
              {revision.dateSent && (
                <Typography variant="p2">
                  {moment(revision.dateSent).format(dateTimeISOFormat)}
                </Typography>
              )}
            </Stack>
            {revision.id !== currentRevision.id && (
              <CaretRight size={20} color={theme.palette.grey[800]} />
            )}
          </Stack>
        </MenuItem>
      ))}
    </Select>
  );
};
