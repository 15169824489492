import { DailyDiaryItemMixed, DailyDiaryItemStatus } from "generated/graphql";
import { DailyDiaryUserRole } from "hooks/useDailyDiaryUserBERole";
import { OwnerButtons } from "./OwnerButtons";
import { CreatorButtons } from "./CreatorButtons";
import { ViewerButtons } from "./ViewerButtons";
import { DailyDiaryHeaderContext } from "./DailyDiaryHeader.context";
import { useMemo } from "react";

export type DDHeaderActionButtonsProps = {
  loading: boolean;
  dailyDiary: DailyDiaryItemMixed;
  dailyDiaryUserRoles?: DailyDiaryUserRole[];
  dailyDiaryStatus?: DailyDiaryItemStatus;
  onSubmit: () => void;
  onSend: () => void;
  onApprove: () => void;
  onDeny: () => void;
  onRevise: () => void;
  // onLockAndEdit: () => void;
  onWaive: () => void;
  onUnWaive: () => void;
};

export const DDHeaderActionButtons: React.FC<DDHeaderActionButtonsProps> = ({
  dailyDiaryUserRoles,
  dailyDiaryStatus,
  dailyDiary,
  loading,
  onSubmit,
  onSend,
  onApprove,
  onDeny,
  onRevise,
  // onLockAndEdit,
  onWaive,
  onUnWaive,
}) => {
  const contextValue = useMemo(
    () => ({
      dailyDiaryUserRoles,
      dailyDiaryStatus,
      dailyDiary,
      loading,
      isReviewer: dailyDiaryUserRoles?.includes(DailyDiaryUserRole.Reviewer),
      onSubmit,
      onSend,
      onApprove,
      onDeny,
      onRevise,
      onWaive,
      onUnWaive,
    }),
    [
      dailyDiaryUserRoles,
      dailyDiaryStatus,
      dailyDiary,
      loading,
      onSubmit,
      onSend,
      onApprove,
      onDeny,
      onRevise,
      onUnWaive,
      onWaive,
    ]
  );

  return (
    <DailyDiaryHeaderContext.Provider value={contextValue}>
      {dailyDiaryUserRoles?.includes(DailyDiaryUserRole.Viewer) ? (
        <ViewerButtons />
      ) : null}
      {dailyDiaryUserRoles?.includes(DailyDiaryUserRole.Creator) ? (
        <CreatorButtons />
      ) : null}
      {dailyDiaryUserRoles?.includes(DailyDiaryUserRole.Owner) ? (
        <OwnerButtons />
      ) : null}
    </DailyDiaryHeaderContext.Provider>
  );
};
