import { useTranslation } from "react-i18next";
import { DailyDiaryHeaderAction } from "./DailyDiaryHeader";
import React, { useMemo } from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { CalendarX, PaperPlaneTilt } from "phosphor-react";

export const DraftStateOwnerActionButtons: React.FC<{
  loading?: boolean;
  sendDisabled?: boolean;
  onClick: (selectedOption: string) => void;
}> = ({ loading, sendDisabled, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const options = useMemo(
    () => [
      {
        label: t(DailyDiaryHeaderAction.Send),
        icon: <PaperPlaneTilt size={18} color={theme.palette.grey[700]} />,
      },
      // {
      //   label: t(DailyDiaryHeaderAction.LockAndEdit),
      //   icon: <Lock size={18} size={18} color={theme.palette.grey[700]} />,
      // },
      {
        label: t(DailyDiaryHeaderAction.Waive),
        icon: <CalendarX size={18} color={theme.palette.grey[700]} />,
      },
    ],
    [t, theme]
  );

  const handleClick = (selectedIndex?: number) => {
    onClick(t(options[selectedIndex ?? 0].label));
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    handleClick(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{ borderRadius: "8px" }}
        disableElevation
        disableRipple
      >
        <Button
          size="large"
          onClick={() => (sendDisabled ? handleClick(1) : handleClick(0))}
          disabled={loading}
        >
          {sendDisabled ? t(options[1].label) : t(options[0].label)}
        </Button>
        <Button
          size="large"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="send waive"
          aria-haspopup="menu"
          sx={{ px: 1 }}
          onClick={handleToggle}
          disabled={loading}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  sx={{ minWidth: "150px" }}
                >
                  {options.map((option, index) =>
                    sendDisabled && index === 0 ? (
                      <Tooltip
                        title={t("Projects.DailyDiaries.atLeastOneRecord")}
                        arrow
                        key={option.label}
                      >
                        <div>
                          <MenuItem disabled>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              {option.icon}
                              <Typography variant="p2" color="grey.800">
                                {t(option.label)}
                              </Typography>
                            </Stack>
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ) : (
                      <MenuItem
                        key={option.label}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          {option.icon}
                          <Typography variant="p2" color="grey.800">
                            {t(option.label)}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
