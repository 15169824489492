import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "components/FormLabel";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import {
  CompEventActionType,
  CompEventQuotation,
  DraftQuotation,
} from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNotifyOfSilenceCEAction } from "./useNotifyOfSilenceCEAction";
import { QuotationsTable } from "../SubmitQuotationCEAction/QuotationsTable/QuotationsTable";
import { Info, Warning } from "phosphor-react";
import { SendNotifyOfSilenceCENotice } from "./SendNotifyOfSilenceCENotice";
import { SendNotifyOfSilenceCEQuotation } from "./SendNotifyOfSilenceCEQuotation";
import { SendNotifyOfSilenceCEOwnAssessment } from "./SendNotifyOfSilenceCEOwnAssessment";
import { RecordNotifyOfSilenceCENotice } from "./RecordNotifyOfSilenceCENotice";
import { RecordNotifyOfSilenceCEQuotation } from "./RecordNotifyOfSilenceCEQuotation";
import { RecordNotifyOfSilenceCEOwnAssessment } from "./RecordNotifyOfSilenceCEOwnAssessment";
import { CEFormPublicApi } from "../CompEventAction/CompEventActionView";
import { useCEQuotationAssessmentModal } from "../../../CEQuotationAssessmentModal/useCEQuotationAssessmentModal";
import { CEQuotationAssessmentReadOnlyModal } from "../../../CEQuotationAssessmentReadOnlyModal/CEQuotationAssessmentReadOnlyModal";

export type NotifyOfSilenceCEActionProps = {
  onClose: () => void;
  apiRef?: React.Ref<CEFormPublicApi>;
  type:
    | CompEventActionType.NotifySilenceCompEventNotice
    | CompEventActionType.NotifySilenceOwnAssessment
    | CompEventActionType.NotifySilenceQuotation;
};

type FormDataType =
  | { remarks?: string }
  | {
      remarks?: string;
      acceptedQuotationId: string;
    };

const defaultFormData: FormDataType = {
  remarks: "",
  acceptedQuotationId: undefined,
};

export const NotifyOfSilenceCEAction: React.FC<
  NotifyOfSilenceCEActionProps
> = ({ apiRef, onClose, type }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { compEvent, contract } = useContext(CompEventWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [promptQuotationLoaded, setPromptQuotationLoaded] =
    useState<boolean>(false);

  const [selectedQuotation, setSelectedQuotation] =
    useState<CompEventQuotation>();

  const {
    modalVisibility: quotationModalVisibility,
    toggleModalVisibility: toggleQuotationModalVisibility,
  } = useCEQuotationAssessmentModal();

  const {
    notifyOfSilenceCEQuotationPrompt,
    notifyOfSilenceOwnAssessmentPrompt,
    sendCENoticeOfSilenceCENotice,
    sendCENoticeOfSilenceCEOwnAssessment,
    sendCENoticeOfSilenceCEQuotation,
    recordCENoticeOfSilenceCENotice,
    recordCENoticeOfSilenceCEOwnAssessment,
    recordCENoticeOfSilenceCEQuotation,
    loading,
    actionLoading,
  } = useNotifyOfSilenceCEAction(type);

  const dataValidators: DataValidators | {} = useMemo(
    () =>
      type === CompEventActionType.NotifySilenceCompEventNotice
        ? {}
        : {
            acceptedQuotationId: {
              validators: [ValidatorType.Required],
            },
          },
    [type]
  );

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleRemarksChange = (updatedRemarks: string) => {
    setFormData((curData) => ({
      ...curData,
      remarks: updatedRemarks,
    }));
  };

  const handleSendCEConfirmation = async () => {
    if (type === CompEventActionType.NotifySilenceCompEventNotice) {
      await sendCENoticeOfSilenceCENotice({
        variables: {
          input: {
            compEventId: compEvent?.id!,
            remarks: formData.remarks,
          },
        },
      });
    } else if (type === CompEventActionType.NotifySilenceQuotation) {
      await sendCENoticeOfSilenceCEQuotation({
        variables: {
          input: {
            compEventId: compEvent?.id!,
            remarks: formData.remarks,
            acceptedQuotationId: quotations?.[0].id,
          },
        },
      });
    } else {
      await sendCENoticeOfSilenceCEOwnAssessment({
        variables: {
          input: {
            compEventId: compEvent?.id!,
            remarks: formData.remarks,
            acceptedQuotationId: quotations?.[0].id,
          },
        },
      });
    }

    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEConfirmation = async (
    extraData: RecordProductItemExtraData
  ) => {
    if (type === CompEventActionType.NotifySilenceCompEventNotice) {
      await recordCENoticeOfSilenceCENotice({
        variables: {
          input: {
            compEventId: compEvent?.id!,
            remarks: formData.remarks,
            dateSent: extraData.dateSent,
            givenById: extraData.givenById,
            number: extraData.number,
          },
        },
      });
    } else if (type === CompEventActionType.NotifySilenceQuotation) {
      await recordCENoticeOfSilenceCEQuotation({
        variables: {
          input: {
            compEventId: compEvent?.id!,
            remarks: formData.remarks,
            acceptedQuotationId: quotations?.[0].id,
            dateSent: extraData.dateSent,
            givenById: extraData.givenById,
            number: extraData.number,
          },
        },
      });
    } else {
      await recordCENoticeOfSilenceCEOwnAssessment({
        variables: {
          input: {
            compEventId: compEvent?.id!,
            remarks: formData.remarks,
            acceptedQuotationId: quotations?.[0].id,
            dateSent: extraData.dateSent,
            givenById: extraData.givenById,
            number: extraData.number,
          },
        },
      });
    }

    toggleRecordModalVisibility();
    onClose();
  };

  const handleQuotationRowClick = (
    quotation: DraftQuotation | CompEventQuotation
  ) => {
    setSelectedQuotation(quotation as CompEventQuotation);
    toggleQuotationModalVisibility();
  };

  useEffect(() => {
    if (
      (notifyOfSilenceCEQuotationPrompt ||
        notifyOfSilenceOwnAssessmentPrompt) &&
      !promptQuotationLoaded
    ) {
      const promptQuotations =
        notifyOfSilenceCEQuotationPrompt?.quotations ||
        notifyOfSilenceOwnAssessmentPrompt?.quotations;
      const defaultAcceptedQuotationId = promptQuotations?.[0]?.id;

      setFormData((crtFormData) => ({
        ...crtFormData,
        acceptedQuotationId: defaultAcceptedQuotationId,
      }));
      setPromptQuotationLoaded(true);
    }
  }, [
    notifyOfSilenceCEQuotationPrompt,
    notifyOfSilenceOwnAssessmentPrompt,
    promptQuotationLoaded,
  ]);

  const isFormValid = useMemo(
    () => validateData(formData, dataValidators).valid,
    [formData, dataValidators]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  const quotations = ((type === CompEventActionType.NotifySilenceQuotation
    ? notifyOfSilenceCEQuotationPrompt?.quotations
    : notifyOfSilenceOwnAssessmentPrompt?.quotations) ??
    []) as CompEventQuotation[];

  if (
    promptQuotationLoaded &&
    type === CompEventActionType.NotifySilenceOwnAssessment &&
    !quotations.length
  ) {
    // notify of silence without quotation is not permitted
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Warning size={24} color={theme.palette.warning.main} />
        <Typography variant="p1">
          {t("Projects.CompEvents.ActionModal.notifyOfSilenceForbidden")}
        </Typography>
      </Stack>
    );
  }

  return loading ? (
    <Box display="flex" justifyContent="center" width="100%">
      <CircularProgress />
    </Box>
  ) : (
    <>
      {quotationModalVisibility && selectedQuotation && (
        <CEQuotationAssessmentReadOnlyModal
          open={quotationModalVisibility}
          onClose={toggleQuotationModalVisibility}
          quotationAssessment={selectedQuotation}
          contract={contract}
        />
      )}
      {isFormValid && sendModalVisibility ? (
        type === CompEventActionType.NotifySilenceCompEventNotice ? (
          <SendNotifyOfSilenceCENotice
            open={sendModalVisibility}
            remarks={formData.remarks}
            onPrimaryClick={handleSendCEConfirmation}
            onSecondaryClick={toggleSendModalVisibility}
            onClose={toggleSendModalVisibility}
            primaryBtnLoading={actionLoading}
          />
        ) : type === CompEventActionType.NotifySilenceQuotation ? (
          <SendNotifyOfSilenceCEQuotation
            open={sendModalVisibility}
            quotations={quotations}
            acceptedQuotationId={quotations?.[0].id}
            remarks={formData.remarks}
            onPrimaryClick={handleSendCEConfirmation}
            onSecondaryClick={toggleSendModalVisibility}
            onClose={toggleSendModalVisibility}
            primaryBtnLoading={actionLoading}
          />
        ) : (
          <SendNotifyOfSilenceCEOwnAssessment
            open={sendModalVisibility}
            quotations={quotations}
            acceptedQuotationId={quotations?.[0].id}
            remarks={formData.remarks}
            onPrimaryClick={handleSendCEConfirmation}
            onSecondaryClick={toggleSendModalVisibility}
            onClose={toggleSendModalVisibility}
            primaryBtnLoading={actionLoading}
          />
        )
      ) : null}
      {isFormValid && recordModalVisibility ? (
        type === CompEventActionType.NotifySilenceCompEventNotice ? (
          <RecordNotifyOfSilenceCENotice
            open={recordModalVisibility}
            remarks={formData.remarks}
            onPrimaryClick={handleRecordCEConfirmation}
            onSecondaryClick={toggleRecordModalVisibility}
            onClose={toggleRecordModalVisibility}
            primaryBtnLoading={actionLoading}
          />
        ) : type === CompEventActionType.NotifySilenceQuotation ? (
          <RecordNotifyOfSilenceCEQuotation
            open={recordModalVisibility}
            remarks={formData.remarks}
            quotations={quotations}
            acceptedQuotationId={quotations?.[0].id}
            onPrimaryClick={handleRecordCEConfirmation}
            onSecondaryClick={toggleRecordModalVisibility}
            onClose={toggleRecordModalVisibility}
            primaryBtnLoading={actionLoading}
          />
        ) : (
          <RecordNotifyOfSilenceCEOwnAssessment
            open={recordModalVisibility}
            remarks={formData.remarks}
            quotations={quotations}
            acceptedQuotationId={quotations?.[0].id}
            onPrimaryClick={handleRecordCEConfirmation}
            onSecondaryClick={toggleRecordModalVisibility}
            onClose={toggleRecordModalVisibility}
            primaryBtnLoading={actionLoading}
          />
        )
      ) : null}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.remarks")} />
            <RichTextArea
              content={formData.remarks ?? ""}
              onChange={handleRemarksChange}
            />
          </Grid>
          {(type === CompEventActionType.NotifySilenceQuotation ||
            type === CompEventActionType.NotifySilenceOwnAssessment) && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={1}>
                <FormLabel
                  label={t(
                    "Projects.CompEvents.ActionModal.quotationToDeemAccepted"
                  )}
                  defaultMarginBottom={false}
                />
                <Box
                  ml={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Tooltip
                    title={t(
                      "Projects.CompEvents.ActionModal.quotationToDeemAcceptedTooltip"
                    )}
                    arrow
                    placement="bottom"
                  >
                    <Info size={16} color={theme.palette.grey[800]} />
                  </Tooltip>
                </Box>
              </Box>
              <QuotationsTable
                contractCurrency={contract.valueCurrency ?? ""}
                loading={loading}
                quotations={quotations}
                selectable
                acceptedQuotationId={
                  quotations && quotations[0] ? quotations[0].id : undefined
                }
                onRowClick={handleQuotationRowClick}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
