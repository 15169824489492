import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { PublicApi } from "../components/RecordModalFooter";
import { RefObject } from "react";

export const useDisableBackdropClick = (
  footerApiRef: RefObject<PublicApi>,
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void,
  infoMsg?: string
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = (
    _event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    footerApiRef.current?.onBeforeClose();

    if (reason === "backdropClick") {
      enqueueSnackbar(t(infoMsg ?? "common.infoMessages.disabledBackdropMsg"), {
        variant: "info",
      });
      return;
    }

    onClose?.(_event, reason);
  };

  return {
    handleClose,
  };
};
