import {
  Box,
  ClickAwayListener,
  MenuProps,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { GridEditSingleSelectCell } from "@mui/x-data-grid-pro";
import { useState } from "react";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;

  .MuiInputBase-root.MuiOutlinedInput-root {
    position: absolute;
    top: 0;
    bottom: 0;
  }
`;

export const DataGridSingleSelect = ({
  cellParams,
  error,
  disabled = false,
  menuProps,
  onValueChange,
}: {
  cellParams: any;
  error?: boolean;
  disabled?: boolean;
  menuProps?: Omit<MenuProps, "open">;
  onValueChange: (event: SelectChangeEvent<any>, newValue: any) => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container
        onClick={() => {
          !disabled && setOpen((crtValue) => !crtValue);
        }}
      >
        <GridEditSingleSelectCell
          {...cellParams}
          error={error}
          MenuProps={{
            className: "custom-popover-60per", // TODO
            ...menuProps,
          }}
          onValueChange={onValueChange}
          disabled={disabled}
          open={open}
          initialOpen={false}
        />
      </Container>
    </ClickAwayListener>
  );
};
