import {
  AvailableLookupsByContractQuery,
  AvailableLookupsByContractQueryVariables,
  CompanyLookupCollection,
} from "generated/graphql";
import { availableLookupsByContractQuery } from "graphql/queries/availableLookupsByContract.query";
import { useDailyDiaryPresets } from "hooks/useDailyDiaryPresets";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

export const useProductInstanceDailyDiaryExtraConfigForm = (
  contractId?: string
) => {
  const {
    data: contractLookupCollections,
    loading: contractLookupCollectionsLoading,
  } = useGraphQuery<
    AvailableLookupsByContractQuery,
    AvailableLookupsByContractQueryVariables
  >(availableLookupsByContractQuery, {
    variables: { contractId: contractId! },
    skip: !contractId,
  });

  const { dailyDiaryPresets, loading: dailyDiaryPresetsLoading } =
    useDailyDiaryPresets();

  const orderedLookups = useMemo(() => {
    const rawLookups = (contractLookupCollections?.availableLookupsByContract
      .items ?? []) as CompanyLookupCollection[];

    rawLookups.sort((lkp1, lkp2) => lkp1.name.localeCompare(lkp2.name));

    return rawLookups;
  }, [contractLookupCollections]);

  return {
    dailyDiaryPresets,
    lookups: orderedLookups,
    loading: contractLookupCollectionsLoading || dailyDiaryPresetsLoading,
  };
};
