import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Comments } from "containers/Projects/components/Comments/Comments";
import { ExplorerContext } from "containers/Projects/components/Explorer/Explorer.context";
import {
  DailyDiaryExplorerDataQuery,
  DailyDiaryExplorerDataQueryVariables,
  DailyDiaryItemStatus,
  ProductType,
} from "generated/graphql";
import { useDailyDiary } from "./hooks/useDailyDiary";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { extendedTheme } from "theme/extendedTheme";
import { DailyDiaryGeneralInfo } from "./components/DailyDiaryGeneralInfo";
import { DailyDiaryHeader } from "./components/DailyDiaryHeader/DailyDiaryHeader";
import { useDailyDiaryHeader } from "./components/DailyDiaryHeader/useDailyDiaryHeader";
import { DDSectionsInterpretor } from "./components/DailyDiaryInterpretor/DDSectionsInterpretor";
import { SendDailyDiaryModal } from "./components/SendDailyDiaryModal/SendDailyDiaryModal";
import { dailyDiaryExplorerDataQuery } from "./DailyDiary.query";
import {
  DailyDiaryContext,
  DailyDiaryContextProvider,
} from "./DailyDiaryContextProvider";
import { NewAppPaths } from "helpers/paths/paths";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { noop } from "helpers/miscelaneous";
import { useDailyDiaryUserRoles } from "hooks/useDailyDiaryUserRoles";
import { ApproveDailyDiaryModal } from "./components/ApproveDailyDiaryModal";
import { DenyDailyDiaryModal } from "./components/DenyDailyDiaryModal";
import { ReviewersPanel } from "./components/ReviewersPanel/ReviewersPanel";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { ReviseDiaryModal } from "./components/ReviseDiaryModal";
import { LockedDailyDiaryBanner } from "./components/LockedDailyDiaryBanner";
import { NotLatestRevisionBanner } from "./components/NotLatestRevisionBanner";
import { useApproveDiaryAuthorizationWorkflow } from "./hooks/useApproveDiaryAuthorizationWorkflow";
import { useDiaryMetadata } from "./hooks/useDiaryMetadata";
import { IntercomEvents } from "constants/intercom";
import { useDiaryTrackingEvents } from "./hooks/useDiaryTrackingEvents";
import { WaiveDailyDiaryModal } from "./components/WaiveDailyDiaryModal";
import { UnwaiveDailyDiaryModal } from "./components/UnwaiveDailyDiaryModal";
import { DiaryWaivedBanner } from "./components/DiaryWaivedBanner";
import {
  scrollToContainer,
  useHeaderLinks,
} from "containers/Projects/hooks/useHeaderLinks";
import { RejectedDiaryBanner } from "./components/RejectedDiaryBanner";

export const DailyDiary: React.FC = () => (
  <ThemeProvider
    theme={(outerTheme) => ({
      ...outerTheme,
      ...extendedTheme,
    })}
  >
    <DailyDiaryContextProvider>
      <DailyDiaryInner />
    </DailyDiaryContextProvider>
  </ThemeProvider>
);

const DailyDiaryInner: React.FC = () => {
  const navigate = useNavigate();
  const { authenticatedUser } = useContext(GlobalContext);
  const { revisionId, dailyDiaryId, changeRevisionId } = useDiaryMetadata();
  const visitedRef = useRef<boolean>(false);
  const reviewersPanelRef = useRef<HTMLDivElement>();

  const {
    loading: dailyDiaryContextLoading,
    dailyDiaryLoading,
    dailyDiaryPreset,
    dailyDiary,
    dailyDiaryItemFetched,
    emptyDailyDiary,
    productInstance,
    refetchDDByDate,
  } = useContext(DailyDiaryContext);

  const { productInstanceId, date: dailyDiaryDate } = useParams();
  const currentRevision = useMemo(
    () => dailyDiary?.revisions.find((revision) => revision.id === revisionId),
    [dailyDiary, revisionId]
  );

  const { userRoles, loading: userRoleLoading } = useDailyDiaryUserRoles(
    productInstanceId!,
    currentRevision?.authorizationWorkflowAudit ?? undefined
  );

  const {
    approveDiaryAuthorizationWorkflow,
    loading: approveDiaryAuthWorkflowLoading,
  } = useApproveDiaryAuthorizationWorkflow(
    productInstanceId!,
    !!currentRevision?.authorizationWorkflowAudit
  );

  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    loading: explorerDataLoading,
    setLoading: setExplorerDataLoading,
    projectId: explorerProjectId,
    contractId: explorerContractId,
    productId: explorerProductId,
  } = useContext(ExplorerContext);

  const {
    modalVisibility: sendDDModalVisibility,
    toggleModalVisibility: toggleSendDDModalVisibility,
  } = useBasicModal();

  const {
    sendDailyDiary,
    sendDailyDiaryLoading,
    approveDailyDiary,
    approveDailyDiaryLoading,
    rejectDailyDiary,
    rejectDailyDiaryLoading,
    reviseDailyDiary,
    reviseDailyDiaryLoading,
    waiveDailyDiary,
    waiveDailyDiaryLoading,
    unWaiveDailyDiary,
    unWaiveDailyDiaryLoading,
  } = useDailyDiary(refetchDDByDate);

  const { sendDiaryTrackingEvent } = useDiaryTrackingEvents();

  const {
    modalVisibility: approveDDModalVisibility,
    toggleModalVisibility: toggleApproveDDModalVisibility,
  } = useBasicModal();

  const {
    modalVisibility: denyDDModalVisibility,
    toggleModalVisibility: toggleDenyDDModalVisibility,
  } = useBasicModal();

  const {
    modalVisibility: reviseDDModalVisibility,
    toggleModalVisibility: toggleReviseDDModalVisibility,
  } = useBasicModal();

  const {
    modalVisibility: waiveDDModalVisibility,
    toggleModalVisibility: toggleWaiveDDModalVisibility,
  } = useBasicModal();

  const {
    modalVisibility: unwaiveDDModalVisibility,
    toggleModalVisibility: toggleUnwaiveDDModalVisibility,
  } = useBasicModal();

  const {
    navigateToNextDate,
    navigateToPreviousDate,
    navigateToSpecificDate: _,
  } = useDailyDiaryHeader(productInstanceId!, dailyDiaryDate!); // TODO:

  const showComments =
    dailyDiary &&
    revisionId &&
    currentRevision &&
    [DailyDiaryItemStatus.Draft, DailyDiaryItemStatus.LockedDraft].indexOf(
      currentRevision.status
    ) < 0;

  const {
    commentsContainerRef,
    commentsCount,
    handleCommentsLoaded,
    hasMoreComments,
    handleCommentsClick,
  } = useHeaderLinks(currentRevision?.id);

  const [fetchExplorerData] = useGraphLazyQueryLite<
    DailyDiaryExplorerDataQuery,
    DailyDiaryExplorerDataQueryVariables
  >(dailyDiaryExplorerDataQuery);

  const diaryInitialLoad = dailyDiaryLoading && !emptyDailyDiary && !dailyDiary;
  const diaryLoadingExceptWhenIsBeingCreated =
    diaryInitialLoad || (dailyDiaryLoading && !!dailyDiary);

  const headerLoading =
    dailyDiaryContextLoading ||
    userRoleLoading ||
    diaryLoadingExceptWhenIsBeingCreated ||
    !!explorerDataLoading;

  const loading =
    userRoleLoading ||
    dailyDiaryContextLoading ||
    (dailyDiaryLoading && !emptyDailyDiary && !dailyDiary); // first load

  const triggerSendDailyDiaryFlow = () => {
    toggleSendDDModalVisibility();
  };

  const handleSendDailyDiary = async () => {
    await sendDailyDiary({ variables: { id: dailyDiary?.id! } });
    dailyDiary &&
      sendDiaryTrackingEvent(IntercomEvents.SentDailyDiary, dailyDiary);
    toggleSendDDModalVisibility();
  };

  const handleApproveDailyDiary = async () => {
    await approveDailyDiary({
      variables: {
        authorizationWorkflowAuditId:
          dailyDiary?.latestRevision.authorizationWorkflowAudit?.id!,
      },
    });
    dailyDiary &&
      sendDiaryTrackingEvent(IntercomEvents.ApprovedDailyDiary, dailyDiary);
    toggleApproveDDModalVisibility();
  };

  const handleDenyDailyDiary = async (denialReason: string) => {
    await rejectDailyDiary({
      variables: {
        authorizationWorkflowAuditId:
          dailyDiary?.latestRevision.authorizationWorkflowAudit?.id!,
        denialReason,
      },
    });
    dailyDiary &&
      sendDiaryTrackingEvent(IntercomEvents.RejectedDailyDiary, dailyDiary);
    toggleDenyDDModalVisibility();
  };

  const handleReviseDailyDiary = async () => {
    if (dailyDiary) {
      const { data } = await reviseDailyDiary({
        variables: {
          id: dailyDiary.id,
        },
      });

      if (data) {
        changeRevisionId(data.reviseDailyDiaryItem.latestRevision.id, false);
      }

      toggleReviseDDModalVisibility();
    }
  };

  const handleWaiveDailyDiary = async (remarks: string) => {
    await waiveDailyDiary({
      variables: {
        input: {
          dailyDiaryId: dailyDiary?.id,
          remarks,
          date: dailyDiaryDate,
          productInstanceId,
        },
      },
    });

    toggleWaiveDDModalVisibility();
  };

  const handleUnwaiveDailyDiary = async () => {
    if (!dailyDiary) return;

    await unWaiveDailyDiary({
      variables: {
        id: dailyDiary?.id,
      },
    });

    toggleUnwaiveDDModalVisibility();
  };

  const populateExplorer = useCallback(async () => {
    const { data } = await fetchExplorerData({ id: productInstanceId! });

    setExplorerDataLoading(false);
    changeExplorerEntities({
      projectId: data.productInstance.contract.projectId,
      contractId: data.productInstance.contract.id,
      productId: data.productInstance.product.id,
      productInstanceId: data.productInstance.id,
    });
  }, [
    fetchExplorerData,
    changeExplorerEntities,
    setExplorerDataLoading,
    productInstanceId,
  ]);

  const commentsEditable = useMemo(() => {
    return authenticatedUser?.roles.items.find(
      (role) =>
        role.productInstanceId === productInstanceId &&
        role.productRole.actions.items.find(
          (prodRoleAction) => prodRoleAction.name === PermissionEnum.AddComment
        )
    );
  }, [authenticatedUser, productInstanceId]);

  const handleGoToLatestRevision = useCallback(() => {
    if (dailyDiary && dailyDiaryId) {
      changeRevisionId(dailyDiary.latestRevision.id, false);
    }
  }, [changeRevisionId, dailyDiary, dailyDiaryId]);

  const handleCommentAdded = (noOfMentions: number) => {
    dailyDiary &&
      sendDiaryTrackingEvent(IntercomEvents.AddedComment, dailyDiary, {
        Product: dailyDiary.productInstance.product.name,
        Mentions: noOfMentions,
      });
  };

  const lastRevisionSelected = revisionId === dailyDiary?.latestRevision.id;

  useEffect(() => {
    if (dailyDiaryItemFetched && !dailyDiary && !emptyDailyDiary) {
      // wrong date, wrong id. Navigate to 404 not found page
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound, { replace: true });
    }
  }, [
    dailyDiaryItemFetched,
    dailyDiary,
    emptyDailyDiary,
    clearExplorerData,
    navigate,
  ]);

  useEffect(() => {
    populateExplorer();
  }, [populateExplorer]);

  useEffect(() => {
    setExplorerDataLoading(true);
  }, [setExplorerDataLoading]);

  useEffect(() => {
    if (!revisionId && dailyDiary && dailyDiaryId) {
      // user navigated to dailyDiary, but didn't specify a revision. Thus, redirecting to latest revision
      handleGoToLatestRevision();
    }
  }, [dailyDiary, revisionId, dailyDiaryId, handleGoToLatestRevision]);

  useEffect(() => {
    if (!visitedRef.current && dailyDiary) {
      sendDiaryTrackingEvent(IntercomEvents.ViewedDailyDiary, dailyDiary);
      visitedRef.current = true;
    }
  }, [sendDiaryTrackingEvent, dailyDiary]);

  const handleBackBtn = () => {
    if (
      explorerProjectId &&
      explorerContractId &&
      explorerProductId &&
      productInstanceId
    ) {
      navigate({
        pathname: NewAppPaths.authorized.Projects.path,
        search: `projectId=${explorerProjectId}&contractId=${explorerContractId}&productId=${explorerProductId}&productInstanceId=${productInstanceId}`,
      });
    }
  };

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <ApproveDailyDiaryModal
        open={approveDDModalVisibility}
        onClose={toggleApproveDDModalVisibility}
        onPrimaryClick={handleApproveDailyDiary}
        primaryBtnLoading={approveDailyDiaryLoading}
        onSecondaryClick={toggleApproveDDModalVisibility}
      />
      <DenyDailyDiaryModal
        open={denyDDModalVisibility}
        onClose={toggleDenyDDModalVisibility}
        primaryBtnLoading={rejectDailyDiaryLoading}
        onPrimaryClick={handleDenyDailyDiary}
        onSecondaryClick={toggleDenyDDModalVisibility}
      />
      <ReviseDiaryModal
        open={reviseDDModalVisibility}
        onClose={toggleReviseDDModalVisibility}
        primaryBtnLoading={reviseDailyDiaryLoading}
        onPrimaryClick={handleReviseDailyDiary}
        onSecondaryClick={toggleReviseDDModalVisibility}
      />
      <WaiveDailyDiaryModal
        open={waiveDDModalVisibility}
        onClose={toggleWaiveDDModalVisibility}
        primaryBtnLoading={waiveDailyDiaryLoading}
        onPrimaryClick={handleWaiveDailyDiary}
        onSecondaryClick={toggleWaiveDDModalVisibility}
      />
      <UnwaiveDailyDiaryModal
        open={unwaiveDDModalVisibility}
        onClose={toggleUnwaiveDDModalVisibility}
        primaryBtnLoading={unWaiveDailyDiaryLoading}
        onPrimaryClick={handleUnwaiveDailyDiary}
        onSecondaryClick={toggleUnwaiveDDModalVisibility}
      />
      <NewPageContentContainer>
        {sendDDModalVisibility && (
          <SendDailyDiaryModal
            open={sendDDModalVisibility}
            primaryBtnLoading={sendDailyDiaryLoading}
            onPrimaryClick={handleSendDailyDiary}
            onClose={toggleSendDDModalVisibility}
            onSecondaryClick={toggleSendDDModalVisibility}
          />
        )}
        <Box height="100%" width="100%">
          <DailyDiaryHeader
            dailyDiary={dailyDiary ?? emptyDailyDiary}
            loading={headerLoading}
            dailyDiaryUserRoles={userRoles}
            currentRevision={currentRevision}
            noActionButtons={!emptyDailyDiary && !lastRevisionSelected}
            onSend={triggerSendDailyDiaryFlow}
            onSubmit={noop}
            onApprove={toggleApproveDDModalVisibility}
            onDeny={toggleDenyDDModalVisibility}
            onRevise={toggleReviseDDModalVisibility}
            onWaive={toggleWaiveDDModalVisibility}
            onUnWaive={toggleUnwaiveDDModalVisibility}
            onNextClick={navigateToNextDate}
            onPreviousClick={navigateToPreviousDate}
            onBack={handleBackBtn}
            commentsCount={showComments ? commentsCount : undefined}
            hasMoreComments={hasMoreComments}
            onCommentsClick={handleCommentsClick}
          />
          <Box mt={3}>
            {loading || !dailyDiaryPreset ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <DDSectionsInterpretor
                sections={dailyDiaryPreset!.sections}
                mainColumnExtraWidgetsBottom={
                  showComments
                    ? [
                        <Grid item xs={12} key="comments-section">
                          <SectionContainer ref={commentsContainerRef}>
                            <Comments
                              key={revisionId}
                              readOnly={!commentsEditable}
                              versionId={revisionId}
                              productType={ProductType.DailyDiary}
                              productItemId={dailyDiary.id}
                              productInstanceId={productInstanceId!}
                              timezone={productInstance?.contract.timeZone}
                              subHeading={
                                currentRevision.status ===
                                DailyDiaryItemStatus.Rejected ? (
                                  <RejectedDiaryBanner
                                    onClick={() =>
                                      reviewersPanelRef?.current &&
                                      scrollToContainer(
                                        reviewersPanelRef.current
                                      )
                                    }
                                  />
                                ) : null
                              }
                              onCommentAdded={handleCommentAdded}
                              onCommentsLoaded={handleCommentsLoaded}
                            />
                          </SectionContainer>
                        </Grid>,
                      ]
                    : []
                }
                mainColumnExtraWidgetsFront={
                  currentRevision?.status === DailyDiaryItemStatus.Waived ||
                  (!lastRevisionSelected && revisionId) ||
                  (dailyDiary?.status === DailyDiaryItemStatus.LockedDraft &&
                    lastRevisionSelected) ? (
                    <Stack direction="column" spacing={2}>
                      {currentRevision?.status ===
                        DailyDiaryItemStatus.Waived && (
                        <DiaryWaivedBanner
                          dateWaived={currentRevision.dateSent}
                        />
                      )}
                      {!lastRevisionSelected && revisionId && (
                        <NotLatestRevisionBanner />
                      )}
                      {dailyDiary?.status ===
                        DailyDiaryItemStatus.LockedDraft &&
                        lastRevisionSelected && <LockedDailyDiaryBanner />}
                    </Stack>
                  ) : null
                }
                secondaryColumnExtraWidgets={[
                  dailyDiary &&
                    currentRevision &&
                    productInstance?.contract.timeZone &&
                    currentRevision.dateSent &&
                    currentRevision.sentBy && (
                      <Box
                        width="100%"
                        key="general-info-section"
                        position="relative"
                      >
                        <SectionContainer>
                          <DailyDiaryGeneralInfo
                            loading={
                              !!dailyDiaryContextLoading || userRoleLoading
                            }
                            contractTimezone={
                              productInstance?.contract.timeZone
                            }
                            isWaived={
                              currentRevision.status ===
                              DailyDiaryItemStatus.Waived
                            }
                            sentBy={currentRevision.sentBy}
                            dateSent={currentRevision.dateSent}
                          />
                        </SectionContainer>
                      </Box>
                    ),
                  dailyDiary &&
                    currentRevision?.status !== DailyDiaryItemStatus.Waived && (
                      <ReviewersPanel
                        key="reviewers-panel"
                        authAudit={
                          currentRevision?.authorizationWorkflowAudit ??
                          undefined
                        }
                        ref={reviewersPanelRef}
                        contractTimezone={productInstance?.contract.timeZone}
                        loading={approveDiaryAuthWorkflowLoading}
                        authWorkflow={approveDiaryAuthorizationWorkflow}
                      />
                    ),
                ]}
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
