import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "types/decl";
import {
  AddDailyDiaryResourceRecordInput,
  Attachment,
  AttachmentInput,
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryResourceRecord,
  EditDailyDiaryResourceRecordInput,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicApi, RecordModalFooter } from "../../RecordModalFooter";
import { ResourceRecordForm } from "./ResourceRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export enum ResourceType {
  Manpower,
  Equipment,
}

export type ResourceRecordModalProps = {
  resourceRecord?: DailyDiaryResourceRecord;
  resourceLookupCollections: CompanyLookupCollection[];
  section: DailyDiaryPresetSection;
  type?: ResourceType;
  attachmentsPreview?: Attachment[];
  loading?: boolean;
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onAddEdit: (
    resourceRecord:
      | AddDailyDiaryResourceRecordInput
      | EditDailyDiaryResourceRecordInput
  ) => void;
  onAddContinously: (resourceRecord: AddDailyDiaryResourceRecordInput) => void;
  onCancel: () => void;
} & Pick<ActionsDialogProps, "onClose" | "open">;

export const ResourceRecordModal: React.FC<ResourceRecordModalProps> = ({
  resourceRecord,
  resourceLookupCollections,
  section,
  type,
  attachmentsPreview,
  onAttachmentsUpdated,
  loading,
  onAddEdit,
  onAddContinously,
  onCancel,
  onClose,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const footerApiRef = useRef<PublicApi>(null);
  const { handleClose } = useDisableBackdropClick(footerApiRef, onClose);

  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const resourceRecordRef = useRef<
    AddDailyDiaryResourceRecordInput | EditDailyDiaryResourceRecordInput
  >();

  const handleResourceRecordChange = (
    updatedResourceRecord:
      | EditDailyDiaryResourceRecordInput
      | AddDailyDiaryResourceRecordInput
  ) => {
    resourceRecordRef.current = updatedResourceRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (resourceRecord) {
        // edit
        onAddEdit(resourceRecordRef.current!);
      } else {
        // add
        const { id, ...addRecordInput } =
          resourceRecordRef.current! as EditDailyDiaryResourceRecordInput;
        onAddEdit(addRecordInput);
      }
    }
  };

  const handleSecondaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      const { id, ...addRecordInput } =
        resourceRecordRef.current! as EditDailyDiaryResourceRecordInput;

      onAddContinously(addRecordInput);

      // clear modal
      formApiRef.current.reset();
      setModalFooterKey((crtKey) => crtKey + 1);
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.${
              type === ResourceType.Manpower
                ? "ManpowerModal"
                : "EquipmentModal"
            }.${resourceRecord ? "editResourceRecord" : "addResourceRecord"}`
          )}
        </Typography>
      }
      fullWidth
      disableEscapeKeyDown
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <ResourceRecordForm
        apiRef={formApiRef}
        resourceLookups={resourceLookupCollections}
        section={section}
        onChange={handleResourceRecordChange}
        resourceRecord={resourceRecord}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        editMode={!!resourceRecord}
        apiRef={footerApiRef}
        loading={loading}
        attachments={attachmentsPreview ?? resourceRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={handleSecondaryBtnClick}
        onTertiaryClick={onCancel}
      />
    </BasicModal>
  );
};
